export const PRESCRIPTION_FORM_STATUS = {
  INITIAL: "INITIAL",
  PARTIAL: "PARTIAL",
  SUBMITTED: "SUBMITTED",
};

export const JOURNEY_STATUS = {
  TREATMENT_COMPLETED: "Treatment Completed",
  REFINEMENT_REQUESTED: "Refinement Requested",
  RETAINER_STAGE: "Retainer Stage",
  RETAINER_ORDERED: "Retainer Ordered",
  TREATMENT_PROGRESS: "Treatment in Progress",
  TX_IN_PROGRESS: "Tx In progress",
  ALIGNER_SHIPPED: "Aligners Shipped",
  ALIGNER_IN_PRODUCTION: "Aligners in Production",
  MODIFICATION_REQUESTED: "Modification Requested",
  TP_COMPLETION_UNDER_REVIEW: "TP Completion Under Review",
};

export const ONBOARDING_PROGRESS = {
  VERIFICATION_PENDING: "VERIFICATION_PENDING",
  ONBOARDING_PENDING: "ONBOARDING_PENDING",
  ONBOARDING_REQUESTED: "ONBOARDING_REQUESTED",
  ONBOARDING_IN_PROGRESS: "ONBOARDING_IN_PROGRESS",
  ONBOARDING_COMPLETED: "ONBOARDING_COMPLETED",
};

export const TAG_STATUS = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  DEFAULT: "default",
};

export const TREATMENT_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const imagesTypes = ["png", "jgp", "jpeg"];

export const SCAN_FILE_TYPES = ["lowerArch", "upperArch", "biteScan","biteScan1","biteScan2"];

export const CHAT_FILE_SIZE = 150000000;
