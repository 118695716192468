import { useState, useEffect, useMemo, useRef } from "react";
import {
  Modal,
  Tabs,
  Row,
  Button,
  Col,
  Typography,
  Divider,
  Upload,
  Spin,
  message,
  Tooltip,
  Image,
} from "antd";
import {
  UserOutlined,
  SendOutlined,
  ReloadOutlined,
  UploadOutlined,
  FileOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import TextEditor from "components/shared/TextEditor/TextEditor";
import ImageViewer from "react-simple-image-viewer";
import { socket, getCommentHistoryService, uploadChatFile, sendMessage, connectSocket } from "api/chat";
import { checkTextEditorContent, removeTrailingEmptyTagsFromString } from "utilities/IPR";
import { imagesTypes, CHAT_FILE_SIZE } from "../../../constants";
import styles from "./commentModal.module.scss";

import "../../../assets/styles/modals/CommentModal.scss";

const { Text } = Typography;
const { TabPane } = Tabs;

const CommentModal = ({
  showCommentsModal,
  toggleCommentModal,
  clinicId,
  partneredLabs,
  patientName,
  patientId,
  commentSenderId,
  setUnreadCount,
}) => {
  const chatInfo = {
    clinic_id: clinicId,
    lab_id: partneredLabs[0]?.lab_id,
    patient_id: patientId,
  };
  const [selectedTabKey, setSelectedTabKey] = useState("1");
  const [inputText, setInputText] = useState("");
  const [senderId, setSenderId] = useState("");
  const [recieverId, setRecieverId] = useState("");
  const [recieverName, setRecieverName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [commentHistory, setCommentHistory] = useState([]);
  const [partnerLabCount, setPartnerLabCount] = useState(partneredLabs);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState([]);

  useEffect(() => {
    document.querySelectorAll(".message-container img").forEach((item) => {
      item.addEventListener("click", (event) => {
        setCurrentImage([item.src]);
        setIsViewerOpen(true);
      });
    });
  }, [commentHistory]);

  const closeImageViewer = () => {
    setCurrentImage([]);
    setIsViewerOpen(false);
  };

  const isImage = (mimeType) => {
    // Extract the file extension from the MIME type
    const extension = mimeType.split("/")[1];

    // Check if the extension exists in the allowedExtensions array
    return imagesTypes.includes(extension);
  };

  useEffect(() => {
    if(socket){
      socket.emit("joinRoom", chatInfo);
      socket.emit("joinRoom", { clinic_id: chatInfo.clinic_id, patient_id: patientId });
      // socket.emit("checkRoom", (response) => {
      //   console.log("room", response);
      // });
      socket.on("message", (comment) => {
        if (!showCommentsModal && comment.module !== "clinic") {
          setUnreadCount((prevCount) => {
            return prevCount + 1;
          });
          // const audioBtn = document.getElementById("audio-btn");
          // audioBtn.click();
  
          // comment.module === "clinic" &&
          //   notificationApi.info({
          //     message: "New Message from clinic",
          //   });
        }
  
        const mappedComment = commentMetaMapping(comment);
        setCommentHistory((prevMessages) => [...prevMessages, mappedComment]);
        scrollToBottom();
      });
    }
    else{
      connectSocket();
    }
   
    return () => {
      socket.off("message", () => {
        console.log("off socket");
      });
    };
  }, [socket]);

  const scrollToBottom = () => {
    setTimeout(() => {
      const chatWindow = document.getElementById("chatWindow");
      if (chatWindow?.scrollHeight > 0) {
        chatWindow.scrollTop = chatWindow?.scrollHeight;
      }
    }, 700);
  };

  useEffect(() => {
    setPartnerLabCount(partneredLabs);
    if (partneredLabs.length > 0 && typeof commentSenderId !== "undefined") {
      const index = partneredLabs.findIndex((x) => x.lab_id === commentSenderId);
      // if (index > -1) {
      //   setSelectedTabKey(parseInt(index + 2) + "");
      // }
    }
  }, [partneredLabs]);

  const OperationsSlot = {
    left: (
      <LeftOutlined
        style={{ marginRight: "1rem", height: "20px", fontSize: "130%" }}
        onClick={() =>
          setSelectedTabKey((prevState) =>
            parseInt(prevState) > setPartnerLabCount.length
              ? parseInt(prevState) - 1 + ""
              : prevState
          )
        }
      />
    ),
    right: (
      <RightOutlined
        style={{ marginRight: "2rem", fontSize: "130%" }}
        onClick={() =>
          setSelectedTabKey((prevState) =>
            parseInt(prevState) <= setPartnerLabCount.length + 1
              ? parseInt(prevState) + 1 + ""
              : prevState
          )
        }
      />
    ),
  };

  const [position, setPosition] = useState(["left", "right"]);
  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }),
      {}
    );
  }, [position]);
  useEffect(() => {
    if (clinicId) {
      setSenderId(clinicId);
      if (selectedTabKey === "1") {
        setRecieverName(patientName);
        setRecieverId(patientId);
      } else {
        partneredLabs.forEach((element, index) => {
          if (index === selectedTabKey - 2) {
            setRecieverId(element.lab_id);
            setRecieverName(element.lab_name);
          }
        });
      }
      if (showCommentsModal) getCommentHistory();
    }
  }, [selectedTabKey, showCommentsModal]);

  const commentMetaMapping = (comment) => {
    const date = dayjs(comment.created_date).format("DD-MM-YYYY hh:mm A");
    const commentCopy = { ...comment, created_date: date };

    if (!comment?.metaData) {
      return commentCopy;
    }
    const metaData = JSON.parse(comment.metaData);

    if (!metaData.mimetype) {
      return commentCopy;
    }
    if (isImage(metaData.mimetype)) {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "image",
        metaData,
      };
    } else {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "file",
        metaData,
      };
    }
  };

  const getCommentHistory = async () => {
    setIsLoading(true);
    try {
      const response = await getCommentHistoryService({
        senderId: chatInfo.clinic_id,
        receiverId: selectedTabKey === "1" ? patientId : chatInfo.lab_id,
        patientId: patientId,
        // comment_description: inputText,
        // module: "clinic",
      });

      const mappedComments = response.data.body.data.map((comment) => {
        const mappedComment = commentMetaMapping(comment);

        return mappedComment;
      });

      setCommentHistory(mappedComments);

      scrollToBottom();
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendHandler = async () => {
    let message = inputText.toString();

    if (!message) {
      return;
    }

    const haveText = checkTextEditorContent(message);

    if (!haveText) {
      return;
    }
    message = removeTrailingEmptyTagsFromString(message);

    sendMessage(
      {
        meta: {
          receiver_id: selectedTabKey === "1" ? chatInfo.patient_id : chatInfo.lab_id,
          sender_id: chatInfo.clinic_id,
          patient_id: chatInfo.patient_id,
          module: "clinic",
        },

        fileContent: "",

        message,
      },
      (response) => {
        if (response && response?.status === "ok") {
          setInputText("");
        } else {
          message.error("server not responding! try again");
        }
        scrollToBottom();
      }
    );
  };
  function urlify(text) {
    const urlRegex = /https?:\/\/[^\s<>"']+(?![^<>]*>)/g;
    return text.replace(urlRegex, function (url, b, c) {
      var url2 = c == "www." ? "http://" + url : url;
      return '<a class="hyperlinksChat" href="' + url2 + '" target="_blank">' + url + "</a>";
    });
  }

  const replaceNewLine = (comment_description) => {
    if (comment_description) {
      comment_description = comment_description.replaceAll("\n", "<br>");
    }
    return comment_description;
  };

  const onFileChange = async (file) => {
    if (file?.size > CHAT_FILE_SIZE) {
      return message.error("One or more files should not be greater than 150MB.");
    }
    const data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    data.append("sender", "clinic");
    data.append("lab_id", chatInfo.lab_id);
    data.append("clinic_id", chatInfo.clinic_id);
    data.append("patient_id", chatInfo.patient_id);

    data.append("receiver", selectedTabKey === "1" ? "patient" : "lab");

    try {
      setIsLoading(true);
      await uploadChatFile(data);
    } catch (err) {
      console.log("err");
      message.error("failed to send file");
    } finally {
      setIsLoading(false);
    }
    // socket.emit('file_upload', data);
    return false;
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    link.click();
    link.remove();
  };

  // const showCommentDetails = (comment = "") => {
  //   const hasContent = checkTextEditorContent(comment);

  //   return (
  //     <div
  //       className={`patient-response ${!hasContent ? "" : "detail-container"}`}
  //       style={{ fontWeight: "400", textAlign: "justify" }}
  //     >
  //       {!hasContent ? (
  //         "--"
  //       ) : (
  //         <div
  //           dangerouslySetInnerHTML={{
  //             __html: treatment?.additionalDetails,
  //           }}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  const showContent = (commentData) => {
    if (commentData?.type === "image") {
      return <Image width={200} src={commentData.comment_description} />;
    }

    if (commentData?.type === "file") {
      return (
        <div
          title={commentData.metaData.name}
          onClick={() => handleDownload(commentData.comment_description, commentData.metaData.name)}
          className={styles["file-container"]}
        >
          <FileOutlined style={{ fontSize: "25px" }} />
          <span className={styles["file-name"]}>{commentData.metaData.name}</span>
        </div>
      );
    }

    return (
      <div
        className={styles["no-margin"]}
        dangerouslySetInnerHTML={{
          __html: urlify(replaceNewLine(commentData.comment_description)),
        }}
      />
    );
  };
  const tabBody = (
    <Spin spinning={isLoading}>
      <div style={{ height: "2rem" }}>
        <Row>
          <Col>
            <Button icon={<UserOutlined />} shape="circle" size="large" />
          </Col>
          <Col span={14} style={{ marginLeft: "10px" }}>
            <Row>
              <Tooltip placement={"bottom"} title={recieverName}>
                <p className="tab-header-receiver-name">{recieverName}</p>
              </Tooltip>
            </Row>
            <Row>
              <Text style={{ fontSize: "12px", fontWeight: "normal" }}>{recieverId}</Text>
            </Row>
          </Col>
          <Col>
            <Button
              style={{ right: "-4rem", border: "none" }}
              icon={<ReloadOutlined />}
              onClick={getCommentHistory}
            />
          </Col>
        </Row>
      </div>
      <Divider />
      <div style={{ height: "17.5rem", overflow: "auto" }} id="chatWindow">
        {commentHistory.length > 0
          ? commentHistory.map((data) =>
              data.sender_id?.includes("CLINIC") ? (
                <div className="senderComment">
                  <Row className="senderHistory">{showContent(data)}</Row>
                  <Row style={{ float: "right", fontSize: "10px" }}>
                    <span>{data.created_date}</span>
                  </Row>
                  <br />
                </div>
              ) : (
                <div className="receiverComment">
                  <Row className="senderHistory">{showContent(data)}</Row>
                  <Row style={{ float: "right", fontSize: "10px" }}>
                    <span>{data.created_date}</span>
                  </Row>
                  <br />
                </div>
              )
            )
          : "No History Available"}
      </div>
    </Spin>
  );

  return (
    <Modal
      style={{ position: "absolute", top: "20%", right: "10px" }}
      visible={showCommentsModal}
      footer={null}
      // bodyStyle={{ display: "none" }}
      width={450}
      onCancel={toggleCommentModal}
    >
      <>
        {/* <button
          id="audio-btn"
          onClick={() => {
            const audio = new Audio(messageTone);

            audio.play();
          }}
        ></button> */}
      </>
      <Tabs
        tabBarExtraContent={partneredLabs.length > 1 ? slot : []}
        onChange={(key) => {
          setSelectedTabKey(key);
        }}
        defaultActiveKey={"1"}
        activeKey={selectedTabKey}
      >
        <TabPane tab={<p className={"tab-header-patient-name"}>Patient</p>} key={1}>
          {selectedTabKey === "1" ? tabBody : ""}
        </TabPane>
        {partneredLabs?.map((lab, index) => (
          <TabPane
            tab={
              <Tooltip placement={"bottom"} title={lab.lab_name}>
                <p className={"tab-header-lab-name"}>{lab.lab_name}</p>
              </Tooltip>
            }
            key={index + 2}
            id={lab.lab_id}
          >
            {selectedTabKey === "2" ? tabBody : ""}
          </TabPane>
        ))}
      </Tabs>

      <div
        style={{ display: "flex", alignItems: "end", justifyContent: "start" }}
        className="comment-editor"
      >
        <TextEditor
          imageCallback={onFileChange}
          editoInput={inputText}
          setEditorInput={setInputText}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div style={{ width: "100%", marginLeft: "1rem" }}>
            <Upload
              multiple
              customRequest={() => {}}
              showUploadList={false}
              beforeUpload={onFileChange}
            >
              <Button shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>

          <Button
            type="primary"
            shape="circle"
            size="large"
            style={{ marginLeft: "12px" }}
            onClick={sendHandler}
            icon={<SendOutlined rotate={315} />}
          />
        </div>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={currentImage}
          currentIndex={0}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Modal>
  );
};

export default CommentModal;
