import { Form, Select, Button, Row, Radio, Col, Input, Tooltip } from "antd";
import { memo } from "react";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as AddIcon } from "../../../../assets/images/teeths/addIcon.svg";
// import { ReactComponent as DeleteIcon } from "../../../../assets/images/teeths/deleteIcon.svg";
import { FORM_CONSTANTS } from "../constants";
import { companyType, primaryColor } from "colors-config";
import { getPhasingInfo } from "messages/messageController";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
const { Option } = Select;
const { TextArea } = Input;
const Aligners = ({
  productList,
  onFormChange,
  disabled,
  prescriptionForm,
  alignerComments,
  setAlignerComments,
  formDataObjectRef,
  labProducts,
  selectedLabProduct,
  showPrescriptionPhasing
}) => {
  // const [showCommentArray, setShowCommentArray] = useState([true]);

  const handleRetainerChange = (value, index) => {
    const updatedShowCommentArray = [...alignerComments];
    updatedShowCommentArray[index] =
      value !== `${FORM_CONSTANTS.NO_RETAINER_AND_WHITENING_TRAY}`;
    setAlignerComments(updatedShowCommentArray);
  };

  const handleChange = (value, field, rowIndex) => {

    //   console.log(" formDataObjectRef.current", formDataObjectRef.current)
    //   console.log(value, field, rowIndex);

    //   console.log(formDataObjectRef.current.treatmentTypes)
    //   const previousTypes = formDataObjectRef.current.treatmentTypes
    //   console.log("previousTypes", previousTypes)
    //  const updated =  previousTypes[rowIndex][field] = value
    //  console.log("updatedupdatedupdated", updated)

    // formDataObjectRef.current = {
    //   ...formDataObjectRef.current,
    //   treatmentTypes: value,
    // };

  };

  return (
    <>
      <h1 className="form-heading" style={{ margin: "0px 0px 10px 0px" }}>
      1. Service Type
      </h1>
      <div className="treatment-row aligner-row">
        <Form.List name="treatmentTypes" disabled={disabled}>
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, fieldKey, ...restField }, index) => (
                <div key={key} style={{ position: "relative" }}>
                  {fields.length > 1 && (
                    <div className="num-btn" style={{ background: primaryColor }}>{index + 1}</div>
                  )}

                  <Row gutter={[8, 8]} align="middle">
                    <Col lg={7}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "aligner"]}
                        name={[name, "aligner"]}
                        label="Aligner"
                        rules={[
                          {
                            required: true,
                            message: "Please select a type",
                          },
                        ]}
                      >
                        <Select
                          disabled={disabled}
                          size="large"
                          className="form-select"
                          placeholder="Select Treatment Type"
                          notFoundContent={
                            <p>No treatment type added in the clinic</p>
                          }
                          onChange={(value) =>
                            handleChange(value, "aligner", index)
                          }
                        >
                          {productList?.map((item) => {
                            return (
                              <Option value={item.product_name}>
                                {item.product_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={16}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "arches"]}
                        name={[name, "arches"]}
                        label="Arches Treated"
                        rules={[
                          {
                            required: true,
                            message: "Please select an Option",
                          },
                        ]}
                      >
                        <Radio.Group
                          disabled={disabled}
                          onChange={(e) =>
                            handleChange(e.target.value, "arches", index)
                          }
                        >
                          <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
                            Upper and Lower
                          </Radio>
                          <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
                          <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col lg={1}>
                      {fields.length > 1 && (
                        <Button
                          className="delete-btn"
                          disabled={disabled}
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            remove(name);
                          }}
                        ></Button>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col lg={7}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "whitening"]}
                        name={[name, "whitening"]}
                        // label="Aligner"
                        rules={[
                          {
                            required: true,
                            message: "Please select a type",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          className="form-select"
                          placeholder="Select Whitening"
                          onChange={(value) =>
                            handleRetainerChange(value, index)
                          }
                          notFoundContent={
                            <p>No treatment type added in the clinic</p>
                          }
                          disabled={disabled}
                        >
                          <Option value={FORM_CONSTANTS.RETAINER}>
                            Retainer{" "}
                          </Option>
                          <Option value={FORM_CONSTANTS.WHITENING_TRAY}>
                            Whitening tray
                          </Option>
                          <Option
                            value={FORM_CONSTANTS.RETAINER_AND_WHITENING_TRAY}
                          >
                            Retainer & Whitening Tray{" "}
                          </Option>
                          <Option
                            value={
                              FORM_CONSTANTS.NO_RETAINER_AND_WHITENING_TRAY
                            }
                          >
                            No Retainer & Whitening Tray
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {alignerComments[index] && (
                      <Col lg={16}>
                        <Form.Item
                          {...restField}
                          fieldKey={[fieldKey, "whitening_arches"]}
                          name={[name, "whitening_arches"]}
                          label=""
                          rules={[
                            {
                              required: true,
                              message: "Please select an Option",
                            },
                          ]}
                        >
                          <Radio.Group
                            disabled={disabled}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "whitening_arches",
                                index
                              )
                            }
                          >
                            <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
                              Upper and Lower
                            </Radio>
                            <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
                            <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
              <Button
                icon={<AddIcon style={{ fill: primaryColor }} />}
                className="addOn-btn"
                onClick={() => {
                  add();
                }}
                disabled={disabled}
              >
                Add Another Treatment
              </Button>
            </>
          )}
        </Form.List>

        {
          companyType === 5 ?
            (
              <>
                <Row gutter={[8, 8]} align="bottom" style={{ marginTop: "15px" }}>
                  <Col lg={7}>
                    <Form.Item name="labProductId" label="Select Product From Lab">
                      <Select
                        placeholder="Product Name"
                        size="large"
                        onChange={(val) => onFormChange("labProductId", val)}
                        disabled={disabled}
                      >
                        <Option value="" >NIL</Option>
                        {labProducts?.map((labProduct, index) => {
                          return (<Option value={labProduct.id} key={index}>{labProduct.productName}</Option>)
                        })}

                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={7}>
                    <Form.Item name="labProductPrice" className="prescriptionProductPrice" label="Product Price">
                      <Input
                        placeholder="Product Price"
                        size="large"
                        // value={selectedLabProduct?.price ? `${selectedLabProduct.price} ${selectedLabProduct.currency}` : ""}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="phasing-row" style={{ paddingTop: "10px" }}>
                  <Row gutter={[8, 8]}>
                    <Col lg={5} style={{ paddingTop: '30px' }}>
                      <div className="label">
                        <label>
                          <span style={{ color: "#ff4d4f" }}>*</span>Phasing
                          <AppToolTip title={getPhasingInfo()}>
                            {/* <AppInfoIcon iconStyles={{ marginLeft: "10px" }}/> */}
                          </AppToolTip>
                        </label>
                      </div>
                    </Col>
                    <Col lg={6} style={{ paddingTop: '25px' }}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please select an Option",
                          },
                        ]}
                        name="phasing"
                        label=""
                      >
                        <Radio.Group
                          onChange={(e) => onFormChange("phasing", e.target.value)}
                          disabled={disabled}
                        >
                          <Radio value={FORM_CONSTANTS.NO}>No</Radio>
                          <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {showPrescriptionPhasing && (
                      <Col lg={12}>
                        <Form.Item
                          name="prescriptionPhasing"
                          label="Required Phases"
                          rules={[
                            {
                              required: true,
                              message: "Please add phasing details",
                            },
                          ]}>
                          <Input
                            placeholder="Enter details"
                            onChange={(e) =>
                              onFormChange("prescriptionPhasing", e.target.value)
                            }
                            disabled={disabled}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>
              </>
            ) : ""
        }

      </div>
    </>
  );
};

export default memo(Aligners);
