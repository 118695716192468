import api from "./api";


export const updateClinicProfileAPI = (data, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/onboarding/updateClinicianProfile/${clinicId}`,
      data,
    });
  };


  export const updateClinicManagementAPI = (data, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/onboarding/updateClinicManagement/${clinicId}`,
      data,
    });
  };


  export const updateClinicTreatmentProductsAPI = (data, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/onboarding/updateProducts/${clinicId}`,
      data,
    });
  };


  export const updateClinicProductAPI = (data,productId, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/onboarding/updateProduct/${clinicId}/${productId}`,
      data,
    });
  };


  export const addClinicProductAPI = (data, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/onboarding/addProduct/${clinicId}`,
      data,
    });
  };


  export const deleteClinicProductAPI = (productId, clinicId) => {
    return api({
      method: "DELETE",
      url: `/clinic/onboarding/deleteProduct/${clinicId}/${productId}`,
    });
  };


  export const updateClinicAppointmentsAPI = (data, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/onboarding/updateAppointments/${clinicId}`,
      data,
    });
  };


  export const updateClinicSkipAPI = (data, clinicId) => {
    return api({
      method: "POST",
      url: `/clinic/doctor-app/updateSkipApp/${clinicId}`,
      data,
    });
  };


