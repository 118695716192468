import { useState, useEffect, useRef } from "react";
import { Form, Spin, message } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import TeethChart from "components/shared/TeethChart/TeethChart";
import Occlusion from "./Occlusion/Occlusion";
import Periodontium from "./Periodontium/Periodontium";
import {
  findMaxPrescriptionVersion,
  handleScanFilesModal,
  initialTreatmentTypesFormData,
  phasingPrescriptionCheck,
} from "utilities/prescription";
import IPRAlignment from "./IPRAlignment/IPRAlignment";
import Duration from "./Duration/Duration";
import Treatment from "./Treatment/Treatment";
import Footer from "./FormFooter/Footer";
import TreatmentType from "./TreatmentTypes/TreatmentTypes";
import { getPreliminaryImagesApi, savePrescriptionAPI } from "api/prescription";
import { FORM_CONSTANTS } from "./constants";
import "./prescriptionForm.scss";
import { getLabProductList } from "api/lab";
import SmileTreatmentType from "./TreatmentTypes/SmileTreatmentType";
import { companyType } from "colors-config";
import PrescriptionPDF from "../PrescriptionPDF/PrescriptionPDF";
import ConfirmationModal from "components/shared/modals/confirmationModal";
import { confirmScanFileAPI, patientClinicalFilesDeleteApi, uploadScanFileAPI } from "api/patient";
import { getTreatmentTypes, updateClinicIntercomJourneyStatus } from "api/clinic";
import { renderTreatmentTypes } from "utilities/commonMethod";
import { SCAN_REQUIRED_MESSAGE } from "utilities/constants";
import { updateClinicFirstTimeStatusUpdate } from "services/redux/actions/clinicActions";
import mixpanel from 'mixpanel-browser';
import SGTreatmentType from "./TreatmentTypes/SGTreatmentType";
import dayjs from 'dayjs';

const PrescriptionForm = ({
  clinic,
  productList,
  durationList,
  labList,
  loadingPdf,
  formDataObjectRef,
  patientId,
  clinicId,
  formData,
  disabled,
  setEditMode,
  editMode,
  PRESCRIPTION_FORM_STATUS,
  prescriptionForm,
  setSelectedVersion,
  setPrescriptionFormData,
  selectedVersion,
  teethChartRef,
  teethChartDraftRef,
  formDraftRef,
  patientInfo,
  treatmentId,
  prescriptionPdfRef,
  setIsPageLoading,
  scansRef
}) => {
  const targetRef = useRef(null);
  const dispatch = useDispatch();
  const { SUBMITTED } = PRESCRIPTION_FORM_STATUS;
  // console.log("formDataObjectRef", formDataObjectRef.current);
  const clinicSelector = useSelector((state) => state.clinic);
  const [termsAndConditionsURL, setTermsAndConditionsURL] = useState("");
  const [dataAcquisitionURL, setDataAcquisitionURL] = useState("");
  // const [openBiteValue, setOpenBiteValue] = useState(null);
  // const [crossBiteValue, setCrossBiteValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alignerComments, setAlignerComments] = useState([false]);
  const [teethChartData, setTeethChartData] = useState([]);
  const [skeletal, setSkeletal] = useState(false);
  const [dental, setDental] = useState(false);
  const [labProducts, setLabProducts] = useState([]);
  const [selectedLabProduct, setSelectedLabProduct] = useState([]);
  const [showPrescriptionPhasing, setShowPrescriptionPhasing] = useState(true);
  const [prescriptionConfirmation, setPrescriptionConfirmation] = useState(false);
  const [submitWithoutScanUrls, setSubmitWithoutScanUrls] = useState(false);
  const [chartValue, setChartValue] = useState("fdi");
  const [disableOpenBite,setDisableOpenBite] = useState(true)
  const [disableCrossBite,setDisableCrossBite] = useState(true)
  const [showAgreeModal,setShowAgreeModal] = useState(false)

  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [scanMessage,setScanMessage] = useState(SCAN_REQUIRED_MESSAGE)

  const getPrescriptionTreatmentTypes = async (labId) => {
    try {
      const treatmentRes = await getTreatmentTypes(labId);
      if (treatmentRes?.data?.data) {
        setTreatmentOptions(treatmentRes?.data?.data);

        if (treatmentRes?.data?.data?.length) {
          const sgTreatmentTypesData = prescriptionForm.getFieldValue("sgTreatmentTypes")
          if(!sgTreatmentTypesData?.length){
            prescriptionForm.setFieldsValue({
              sgTreatmentTypes: initialTreatmentTypesFormData(treatmentRes?.data?.data),
            });
          }
          
        }
      
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (formData) {
      if (Object.keys(formData).length > 0) {
        const alignerComments = formData?.treatmentTypes?.map((aligner) => {
          if (
            aligner.whitening ===
            FORM_CONSTANTS.NO_RETAINER_AND_WHITENING_TRAY ||
            aligner.retainer_and_whitening ===
            FORM_CONSTANTS.NO_RETAINER_AND_WHITENING_TRAY
          ) {
            return false;
          }
          return true;
        });
        setAlignerComments(alignerComments);
      }

      if (formData?.teethChart?.length > 0) {
        const mergedData = formData?.teethChart.reduce((acc, currentItem) => {
          const existingItem = acc.find(
            (item) => item.tooth_no === currentItem.tooth_no
          );
          if (existingItem) {
            if (currentItem.isAdditional) {
              existingItem.addOns.push({
                ...currentItem,
                procedure: currentItem.toothProcedure,
              });
            } else {
              existingItem = { ...existingItem, ...currentItem, addOns: [] };
            }
          } else {
            acc.push({
              ...currentItem,
              procedure: currentItem.toothProcedure,
              toothNo: currentItem.tooth_no,
              addOns: currentItem.isAdditional
                ? [{ ...currentItem, procedure: currentItem.toothProcedure }]
                : [],
            });
          }

          return acc;
        }, []);
        setTeethChartData(mergedData);
      }
      if (formData?.angle_skeleton_class === FORM_CONSTANTS.CLASS_TWO) {
        setSkeletal(true);
      }

      if (formData?.angle_dental_class === FORM_CONSTANTS.CLASS_TWO) {
        setDental(true);
      }
      if (formData?.prescription_complaint_cross_bite_anterior === FORM_CONSTANTS.AS_NEEDED) {
        setDisableCrossBite(false);
      }
      if (formData?.prescription_complaint_open_bite_anterior === FORM_CONSTANTS.AS_NEEDED) {
        setDisableOpenBite(false);
      }
      const check = phasingPrescriptionCheck(formData.phasing);
      console.log('check', formData.phasing);
      setShowPrescriptionPhasing(check);
    }
  }, [formData]);

  useEffect(() => {
    if (labList?.body?.data?.length) {
      const labId = labList.body.data[0].lab_id;
      updateTermsApi(labId);
      getAllLabProducts(labId);
      getPrescriptionTreatmentTypes(labId)

    }
  }, [labList]);

  useEffect(() => {
    if (formData?.labProductId && labProducts?.length) {
      const findLabProduct = labProducts.find(
        (obj) => obj.id == formData?.labProductId
      );
      if (findLabProduct) {
        prescriptionForm.setFieldsValue({
          labProductPrice: `${findLabProduct.price} ${findLabProduct.currency}`,
        });
      }
    }
  }, [labProducts, formData]);



  const submitPrescription = async (values) => {
    setIsPageLoading(true)
    let formValues = {...values};
    const flattenedData = teethChartRef.current?.toothStatuses.flatMap(
      (teeth) => [{ ...teeth, addOns: [] }, ...teeth.addOns]
    );
    // console.log("flattenedData", flattenedData)
    const flatCheck = flattenedData.filter(
      (item) => item.toothNo === null || item.toothNo === ""
    );
    // console.log("flatCheck", flatCheck)
    if (flatCheck.length > 0) {
      return message.error("Selected tooth for status is missing");
    }

   if (formData?.id) {
      formValues.prescription_id = formData.prescription_id;
      formValues.id = formData.id;
      const existingTypes = [];
      const newTypes = [];

      if (values.treatmentTypes) {
        values.treatmentTypes.forEach((el) => {
          let matched = false;
          formData.treatmentTypes.forEach((type) => {
            if (
              el.aligner === type.aligner &&
              el.arches === type.arch_treated
            ) {
              matched = true;

              existingTypes.push({ ...el, id: type.id });
            }
          });

          if (!matched) {
            newTypes.push(el);
          }
        });
      }

      formValues.treatmentTypes = existingTypes.concat(newTypes);
      formValues.oldPrescription = renderTreatmentTypes(companyType, formData?.createdAt).old
    }
      const apiPayload = {
        ...formValues,
        teethChart: flattenedData,
        patientId,
        clinicId,
        estimated_date: values.estimated_date ? dayjs(values.estimated_date ).format("YYYY-MM-DD"): null
      };
      try {
       const { data } = await savePrescriptionAPI(apiPayload);
        if (data) {
          mixpanel.people.set({
            submitted_first_prescription: true
          });
   
          let newVersion = 1;
          let formMessage = "Prescription added Successfully";
          const maxVersion = findMaxPrescriptionVersion(
            formData?.prescriptionVersions
          );
          let updatedForm = { ...formValues };
          if (
            maxVersion >= newVersion &&
            maxVersion >= formData?.prescriptionVersions?.length
          ) {
            newVersion = maxVersion + 1;
          updatedForm.prescriptionVersions = [
            ...formData?.prescriptionVersions,
            {
              value: newVersion,
              label: `Version ${newVersion.toFixed(1)}`,
            },
          ];
          formMessage = "Prescription updated Successfully";
          } else {
            updatedForm.prescriptionVersions = [
              {
                value: newVersion,
                label: "Version 1.0",
              },
            ];
          }
          if(data?.prescriptionId){
            updatedForm.prescription_id = data.prescriptionId
          }
          if(data?.id){
            updatedForm.id = data.id
          }
          if(formData?.createdAt){
            updatedForm.createdAt = formData?.createdAt
          }
          setPrescriptionFormData(updatedForm);
          setSelectedVersion(newVersion);
          setEditMode(SUBMITTED);
          message.success(formMessage);
          if (clinicSelector?.firstTimeOnboard && clinic?.firstTimeOnboardStep == "ADD_PRESCRIPTION") {
            updateClinicIntercomJourneyStatus(clinic?.clinicId, {
              firstTimeOnboardStep: "INVITE_APP"
            })
            dispatch(updateClinicFirstTimeStatusUpdate("INVITE_APP"))
            setTimeout(()=>{
              window.Intercom('startTour', process.env.REACT_APP_INTERCOM_TOUR_INVITE_APP);
            },1000)
          }
        }
        const currentRef = formDataObjectRef.current;
        if (currentRef) {
          currentRef.touched = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setIsPageLoading(false)
        // setSubmitWithoutScanUrls(false)
      }
  }

  useEffect(()=>{
    if(submitWithoutScanUrls)
      {
        submitPrescription()
      }
  },[prescriptionConfirmation ,submitWithoutScanUrls])

  const onFormChange = (name, value) => {
    if (name === "phasing") {
      const check = phasingPrescriptionCheck(value);
      setShowPrescriptionPhasing(check);
    }

    formDataObjectRef.current = {
      ...formDataObjectRef.current,
      [name]: value,
    };
    formDraftRef.current = true;
    if (name === "labProductId") {
      const findLabProduct = labProducts.find((obj) => obj.id == value);
      if (findLabProduct) {
        prescriptionForm.setFieldsValue({
          labProductPrice: `${findLabProduct.price} ${findLabProduct.currency}`,
        });
      } else {
        prescriptionForm.setFieldsValue({ labProductPrice: "" });
      }
    }
  };

  const getAllLabProducts = async (labId) => {
    try {
      const labProductsRes = await getLabProductList(labId);
      if (labProductsRes?.data?.labProducts?.length) {
        setLabProducts(labProductsRes.data.labProducts);
      }
    } catch (error) {
      message.error("Some Error occured!");
      console.error(error);
    }
  };

  const updateTermsApi = async (lab) => {
    try {
      const url = await dentistClinicApiTasks.getAll(
        "clinic/labPartner/getResource",
        {
          labId: lab,
        }
      );
      // formDataObject.selectLab = lab;
      setTermsAndConditionsURL(url.body.terms_and_conditions.url);
      setDataAcquisitionURL(url.body.data_acquisition_form.url);
      // formDataObject.dataAcquisitionURL = url.body.data_acquisition_form.url;
      // formDataObject.termsAndConditionsURL = url.body.terms_and_conditions.url;
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (values) => {
      submitPrescription(values)
  };


  const prescriptionSubmitHandler = () =>{
    const formValues  = prescriptionForm.getFieldsValue();
    let arches;
    arches = formValues.sgTreatmentTypes ?  formValues?.sgTreatmentTypes[0].smilelignArches: {}
    if (companyType === 5 || companyType === 7) {
      arches = formValues.smilelignArches
    }
    const res = handleScanFilesModal(scansRef?.current, arches)
    setScanMessage(res.message)
    if (res.test) {
      setPrescriptionConfirmation(true)
    }
    else{
      prescriptionForm.submit();
    }
  }

  const onFormSubmit = ()=>{
    if (companyType ===7) {
      setShowAgreeModal(true)
    }
    else{
      prescriptionSubmitHandler()
    }
   
  }

  const onConfirmationOk=()=>{
    prescriptionForm.submit();
  }

  const onFinishFailed = errorInfo => {
     targetRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
  };

  const onFieldsChange = (changedFields) => {
    if (changedFields.length > 0) {
      const currentRef = formDataObjectRef.current;
      if (currentRef) {
        currentRef.touched = true;
      }
    }
  };

  return (
    <div className="prescription-form-container">
      <Spin spinning={loading}>
        <Form
          loading={loadingPdf}
          layout="vertical"
          form={prescriptionForm}
          name="prescription-form"
          onFinish={onFinish}
          className="prescription-form"
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          // scrollToFirstError
          // initialValues={{
          //   formInitial,
          // }}
        >
          <div className="formRow">
            <div
              style={{
                background: "#fff",
                borderRadius: "8px",
                padding: "24px 20px 16px 20px",
              }}
            >
              {renderTreatmentTypes(companyType, formData?.createdAt).show ? (
                companyType === 1 ? (
                  <SGTreatmentType
                    onFormChange={onFormChange}
                    disabled={disabled}
                    showPrescriptionPhasing={showPrescriptionPhasing}
                    treatmentOptions={treatmentOptions}
                    prescriptionForm={prescriptionForm}
                  />
                ) : (
                  <SmileTreatmentType
                    onFormChange={onFormChange}
                    disabled={disabled}
                    showPrescriptionPhasing={showPrescriptionPhasing}
                    treatmentOptions={treatmentOptions}
                  />
                )
              ) : (
                <TreatmentType
                  alignerComments={alignerComments}
                  setAlignerComments={setAlignerComments}
                  productList={productList}
                  onFormChange={onFormChange}
                  disabled={disabled}
                  prescriptionForm={prescriptionForm}
                  formDataObjectRef={formDataObjectRef}
                  labProducts={labProducts}
                  selectedLabProduct={selectedLabProduct}
                  showPrescriptionPhasing={showPrescriptionPhasing}
                />
              )}
                  <Duration
                    durationList={durationList}
                    labList={labList}
                    setTermsAndConditionsURL={setTermsAndConditionsURL}
                    setDataAcquisitionURL={setDataAcquisitionURL}
                    onFormChange={onFormChange}
                    disabled={disabled}
                    title={"2. Duration & Prescription Details"}
                  />
                  <IPRAlignment
                    onFormChange={onFormChange}
                    disabled={disabled}
                    titleTwo={"3. Alignment"}
                    title={"4. IPR & Attachments"}
                  />
                  <Treatment
                    onFormChange={onFormChange}
                    disabled={disabled}
                    setDisableOpenBite={setDisableOpenBite}
                    disableOpenBite={disableOpenBite}
                    disableCrossBite={disableCrossBite}
                    setDisableCrossBite={setDisableCrossBite}
                    prescriptionForm={prescriptionForm}
                    title={"5. Treatment Instructions"}
                  />
           
            </div>
            <div className="form-col-two">
              <TeethChart
                teethChartData={teethChartData}
                ref={teethChartRef}
                teethChartDraftRef={teethChartDraftRef}
                country={clinic?.country}
                disabled={disabled}
                clinicId={clinic?.clinicId}
                teethStructureValue={clinic?.teethStructure}
                chartValue={chartValue}
                setChartValue={setChartValue}
              />
              <Occlusion
                onFormChange={onFormChange}
                disabled={disabled}
                skeletal={skeletal}
                dental={dental}
                setSkeletal={setSkeletal}
                setDental={setDental}
                angle_skeleton_side={formData?.angle_skeleton_side}
                angle_dental_side={formData?.angle_dental_side}
                formData={formData}



              />
              <Periodontium onFormChange={onFormChange} disabled={disabled} />
            </div>
          </div>
          <Footer
            termsAndConditionsURL={termsAndConditionsURL}
            dataAcquisitionURL={dataAcquisitionURL}
            disabled={disabled}
            setEditMode={setEditMode}
            editMode={editMode}
            PRESCRIPTION_FORM_STATUS={PRESCRIPTION_FORM_STATUS}
            onFormSubmit={onFormSubmit}
          />
        </Form>
        <div id="targetDiv" ref={targetRef} style={{ height: "10px" }} />
      </Spin>
      {prescriptionConfirmation && (
        <ConfirmationModal
          title="Are you sure you want to submit without relevant scans?"
          content={scanMessage}
          showModal={prescriptionConfirmation}
          setShow={setPrescriptionConfirmation}
          onConfirmation={onConfirmationOk}
          okText="Submit"
        ></ConfirmationModal>
      )}
      <div ref={prescriptionPdfRef}>
        <div style={{ display: "none" }} className="print-part-pdf">
          <PrescriptionPDF
            clinic={clinic}
            prescriptionForm={formData}
            teethChartData={teethChartData}
            chartValue={chartValue}
            patientInfo={patientInfo}
            selectedVersion={selectedVersion}
            patientId={patientId}
            treatmentId={treatmentId}
            treatmentOptions={treatmentOptions}
          />
        </div>
      </div>

      {showAgreeModal && (
        <ConfirmationModal
          title="Are you sure?"
          content={
            "Do you want to continue submitting this prescription form to the lab? Please note that there could be costs in relation to this as agreed with your lab."
          }
          showModal={showAgreeModal}
          setShow={setShowAgreeModal}
          onConfirmation={prescriptionSubmitHandler}
          okText="Confirm"
        ></ConfirmationModal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(PrescriptionForm);
