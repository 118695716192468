import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col, Typography } from "antd";
import { Menu, Avatar, Dropdown, Badge } from "antd";
import AddOrEditPatient from "../shared/modals/AddOrEditPatient";
import IframeModel from "../shared/modals/IframeModel";
import LogoutConfirmationModal from "../shared/modals/logoutConfirmationModal";

import { connect, useDispatch } from "react-redux";
import { logout } from "../../services/redux/actions/authActions";

import "../../assets/styles/components/headerComponent.scss";
import { Link, useHistory } from "react-router-dom";
import Appointment from "../shared/modals/Appointment/Appointment";
import HandsIcon from "../shared/Icon/HandsIcon"

import {
  BellOutlined,
  UserOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  headerColor,
  headerLogo,
  navBarTextColor,
  logoWidth,
  logoMarginLeft,
} from "colors-config";
import SearchBar from "components/shared/SearchBar/SearchBar";
import AppButton from "components/shared/AppButton/AppButton";
import { increaseAddPatientCountApi } from "api/clinic";
import { openNewPatientDailog } from "services/redux/actions/clinicActions";
import DoctorAppModel from "components/shared/DoctorAppModel/DoctorAppModel";
import { deleteDeviceTokenApi } from "api/clinic";
const { Text, Title } = Typography;
function HeaderComponent({ logout, clinic }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [showPopupAddPatient, setShowPopupAddPatient] = useState(false);
  const [showPopupAddAppointment, setShowPopupAddAppointment] = useState(false);

  const [showLogoutConfirmationModel, setShowLogoutConfirmationModel] = useState(false);
  const [showScanModel, setShowScanModel] =useState(false);  
  const [clinicLabel,setClinicLabel] = useState("")

  const [showBookingIframe, setShowBookingIframe] = useState(false);


  const handleMenuClick = (event) => {
    if (+event.key === 3) {
      setShowLogoutConfirmationModel(true);
    }
    if (+event.key === 2) {
      history.push("/changePassword");
    }
    if (+event.key === 4) {
    setShowScanModel(true)
    }
  };

  useEffect(() => {
    if (clinic?.openNewPatient && clinic?.firstTimeOnboard && clinic.intercomAddPatientCount < 2) {
      setShowPopupAddPatient(true);
      increaseAddPatientCountApi(clinic.clinicId);
      dispatch(openNewPatientDailog(false))
    }
    if (clinic) {
      const clinicAddress = clinic?.multipleAddresses?.find((address) => address?.default === 1)
      setClinicLabel(clinicAddress?.label)
    }

  }, [clinic])

  useEffect(() => {
    // Route change detection
    setShowSearchBar(location.pathname !== "/clinic/patients");
  }, [location]);

  useEffect(() => {
    if (queryParams && queryParams.get("appointment") === "open") {
      setShowPopupAddAppointment(true);
    }
  }, []);

  const handleNewItem = (event) => {
    if (+event.key === 1) {
      setShowPopupAddPatient(true);
    }
    if (+event.key === 2) {
      setShowPopupAddAppointment(true);
    }
  };

  const signOut = async () => {
    // localStorage.removeItem("prescription_form_data_array");
    const deviceToken = localStorage.getItem("device_token");
    try {
      await deleteDeviceTokenApi({
        user_id: clinic?.clinicId,
        device_token: deviceToken,
      })
      localStorage.removeItem("device_token");
      window.Intercom('shutdown');
    } catch (error) {
      console.log("error while removing token", error);
    }
    logout();
  };

  const onExistingPatientClick = () => {
    setShowBookingIframe(false);
    setShowPopupAddAppointment(true);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        paddingTop: "0.5rem",
        marginTop: "0.4rem",
        width: "10rem",
      }}
      mode="horizontal"
    >
       <Menu.Item key={4}>Get Doctor App <HandsIcon/>  </Menu.Item>
      <Menu.Item key={2}>Change Password</Menu.Item>
      <Menu.Item key={3}>Logout</Menu.Item>
     
    </Menu>
  );
  const AddPatient = (
    <Menu
      onClick={handleNewItem}
      style={{
        paddingTop: "0.5rem",
        marginTop: "0.5rem",
        width: "10rem",
      }}
      mode="horizontal"
    >
      <Menu.Item className="item-patient" key={1} style={{ textAlign: "right" }}>
        <UserAddOutlined style={{ fontSize: "16px" }} /> Patient
      </Menu.Item>

      <Menu.Item key={2} style={{ textAlign: "right" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <img
            src={require("../../assets/images/appointment.png")}
            style={{ width: "14px" }}
            alt=""
          />
          Appointment
        </div>
      </Menu.Item>
    </Menu>
  );

  const onSelectCallback = (selectedPatient) => {
    console.log(selectedPatient);

    history.push(`/clinic/patient/${selectedPatient.patient_id}`);
    window.location.reload();
    // window.location = `/clinic/patient/${selectedPatient.patient_id}`
  };

  const handleOk = () =>{
    setShowScanModel(false)
  }
  const handleCancel = () =>{
    setShowScanModel(false)
  }
  return (
    <div
      style={{
        backgroundColor: headerColor,
        height: "4rem",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        position: "fixed",
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        paddingBottom: "2rem",
        zIndex: 1000,
      }}
    >
      {showLogoutConfirmationModel && (
        <LogoutConfirmationModal
          title="Logout"
          showModal={showLogoutConfirmationModel}
          onConfirmation={signOut}
          setShow={setShowLogoutConfirmationModel}
        ></LogoutConfirmationModal>
      )}
      {showPopupAddAppointment && (
        <Appointment
          setShowAppointment={setShowPopupAddAppointment}
          showAppointment={showPopupAddAppointment}
          setShowBookingIframe={setShowBookingIframe}
        />
      )}

      {showScanModel && (
      <DoctorAppModel 
      isModalVisible={showScanModel}
      handleOk={handleOk}
      handleCancel={handleCancel}
      />
      )}

      {showBookingIframe && (
        <IframeModel
          title={
            <div className="titleSpan">
              <UserAddOutlined style={{ fontSize: "35px" }} />{" "}
              <Title level={5} style={{ margin: "0px" }}>
                Book Appointment
              </Title>
              <AppButton
                type="primary"
                shape="round"

                onClick={onExistingPatientClick}
              >
                Existing Patient
              </AppButton>
            </div>
          }
          showModal={showBookingIframe}
          setShow={setShowBookingIframe}
        />
      )}
      {showPopupAddPatient && (
        <AddOrEditPatient
          title={
            <div className="titleSpan">
              <UserAddOutlined style={{ fontSize: "35px" }} />{" "}
              <Title level={5} style={{ margin: "0px" }}>
                NEW PATIENT
              </Title>
            </div>
          }
          showModal={showPopupAddPatient}
          setShow={setShowPopupAddPatient}
          onConfirmation={() => { }}
        />
      )}
      <Row justify="space-between">
        <Col md={8}>
          <div style={{ textAlign: "left" }}>
            <Link to={"/"}>
              <img
                style={{
                  width: logoWidth,
                  marginLeft: logoMarginLeft
                }}
                className={`header-logo`}
                src={headerLogo}
                alt="logo"
              />
            </Link>
          </div>
        </Col>
        <Col md={16}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              justifyContent: "flex-end",
            }}
          >
            {
              clinic.isOnboarded && (
                <div style={{ width: "280px", marginTop:"-10px" }}>
                  {showSearchBar && (
                    <SearchBar
                      clinicId={clinic.clinicId}
                      onSelectCallback={onSelectCallback}
                      enterButton={false}
                    />
                  )}
                </div>
              )
            }

            {
              clinic.isOnboarded && (
                <Dropdown
                  overlay={AddPatient}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayClassName="new-patient-btn-dropdown"
               
                >
                   <AppButton icon={<PlusOutlined/>} type="primary">
                    New
                  </AppButton>
                </Dropdown>
              )

            }

            {
              clinic.isOnboarded && (
                <Badge count={clinic.notificationCount}>
                  <BellOutlined
                    style={{
                      fontSize: "25px",
                      color: "#77849E",
                    }}
                    onClick={() => {
                      history.push("/clinic/notifications");
                    }}
                  />
                </Badge>
              )
            }
            <div
              style={{
                borderLeft: "2px solid #EFEFF9",
                display: "inline-block",
                padding: "14px",
              }}
            >
              <Dropdown overlay={menu} trigger={["hover"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  style={{display:"flex", alignItems:"center"}}
                >
                  <Avatar
                    size={39}
                    icon={<UserOutlined  />}
                    style={{ marginRight: "0.5rem" }}
                  />
                  <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                  <Text
                    style={{ fontWeight: "bold", color: navBarTextColor }}
                  >
                    {clinic.clinicName ? clinic.clinicName : ""}

                  </Text>
                  <span
                    style={{ display:"block",color: navBarTextColor }}
                  >
                   {clinicLabel}

                  </span>
                  </div>
                 
                </a>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, { logout })(HeaderComponent);
