import dayjs from "dayjs";
import { Row, Col, Divider } from "antd";
import {ReactComponent as UserPdf} from "assets/images/UserPdf.svg";
import { getDisplayGender } from "utilities/Patient";
const PatientInfo = ({ patientInfo, clinic }) => {
  // console.log("clinicclinicclinic", clinic);
  return (
    <div className="prescriptionPDF-patientContainer">
      <Row align="top" justify="space-between">
        <Col lg={2}>
         <UserPdf/>
        </Col>
        <Col lg={11}>
          <div className="prescriptionPDF-patientRow">
            <label>Patient Name</label>
            <b>
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_first_name
                : ""}{" "}
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_last_name
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Gender</label>
            <b>
              {getDisplayGender(patientInfo)}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Date of Birth</label>
            <b>
              {patientInfo?.patient_date_of_birth
                ? dayjs(patientInfo?.patient_date_of_birth).format("DD-MM-YYYY")
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Phone Number</label>
            <b>
              {patientInfo?.patient_phone_number
                ? patientInfo?.patient_phone_number
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Address</label>
            <b>
              {patientInfo?.patient_address
                ? `${patientInfo?.patient_address},`
                : "--"}
                <br/>
                {patientInfo?.patient_city
                ? `${patientInfo?.patient_city},`
                : ""}
                {patientInfo?.patient_county
                ? `${patientInfo?.patient_county},`
                : ""}
                <br/>
                {patientInfo?.patient_country
                ? `${patientInfo?.patient_country},`
                : ""}
                {patientInfo?.patient_postal_code
                ? `${patientInfo?.patient_postal_code}`
                : ""}
            </b>
          </div>
        </Col>
        <Col lg={11}>
          <div className="prescriptionPDF-patientRow">
            <label>Clinic Name</label>
            <b>
                {clinic?.clinicName
                ? `${clinic?.clinicName},`
                : "--"}
               
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Doctor Name</label>
            <b>
              {patientInfo?.clinicianDetails?.clinician_name
                ? patientInfo?.clinicianDetails?.clinician_name
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Email </label>
            <span>
              {" "}
              {clinic?.clinicEmail
                ? clinic?.clinicEmail
                : "--"}
            </span>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Phone Number</label>
            <span>
            {clinic?.clinicCountryCode
                ? clinic?.clinicCountryCode
                : ""}
              -
              {clinic?.clinicPhoneNumber
                ? clinic?.clinicPhoneNumber
                : "--"}
            </span>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Address</label>
            <span> 
                {clinic?.address
                ? `${clinic?.address},`
                : "--"}
                <br/>
                {clinic?.clinicCity
                ? `${clinic?.clinicCity},`
                : ""}
                {clinic?.clinicCounty
                ? `${clinic?.clinicCounty},`
                : ""}
                <br/>
                {clinic?.country
                ? `${clinic?.country},`
                : ""}
                {clinic?.zipCode
                ? `${clinic?.zipCode}`
                : ""}
                
                
                </span>
          </div>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default PatientInfo;
