import {useState} from "react"
import { Modal, Button, message } from "antd";
import Header from "./components/Header";
import { useSelector, useDispatch } from "react-redux";
import "./doctorModal.scss";
import ScanApp from "./components/ScanApp";
import { updateClinicSkipAPI } from "api/settingsApi";
import { clinicIdSelector } from "services/redux/selectors/clinicSelectors";
import { updateClinicAppSkipAction } from "services/redux/actions/clinicActions";

const DoctorAppModel = ({ isModalVisible, handleOk, handleCancel, doNotShow  }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();


  const clinicId = useSelector(clinicIdSelector);

  const handleDoNotShow = async () => {
    try {
      setLoading(true);
      const payload={
        skipApp :true
      }
      const { data } = await updateClinicSkipAPI(payload, clinicId);
      dispatch(updateClinicAppSkipAction(true)) 
      message.success("Clinic touch point setting updated");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      handleCancel()
  
    }
  };
  return (
    <Modal
      title={<Header />}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      okButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      width={1020}
      className="doctors-app-container"
      closeIcon={null}
    >
      <ScanApp />
      <div className="skip-footer">
        <Button
          shape="round"
          type="primary"
          style={{ width: "140px", marginLeft:"20px" }}
          onClick={handleCancel}
          className="skip-footerBtn"
          size="large"
        >
          Skip
        </Button>
        {
          doNotShow && <Button
          shape="round"
          style={{ width: "200px" }}
          onClick={handleDoNotShow}
          className="skip-footerBtn"
          size="large"
          loading={loading}
        >
          Don't show me again
        </Button>
        }
        
      </div>
    </Modal>
  );
};

export default DoctorAppModel;
