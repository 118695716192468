import firebase from "firebase/app";
import "firebase/messaging";
import { updateNotificationState } from "./App";

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBlqs2SsPJB-_yGhJ9P49_xjDGh11RVrOM",
//   authDomain: "smilegeniusdental-306708.firebaseapp.com",
//   projectId: "smilegeniusdental-306708",
//   storageBucket: "smilegeniusdental-306708.appspot.com",
//   messagingSenderId: "479527472680",
//   appId: "1:479527472680:web:8f743d14765cb8b27e6f1d",
//   measurementId: "G-PMCCBKDB9F"
// };

firebase.initializeApp(firebaseConfig);

// if (firebase.messaging.isSupported())
let messaging = {};

try{
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  } else {
    // alert("This browser does not support push notifications.");
  }
} catch (error) {
  console.log("error");
  
}


export const getFirebaseToken = () => {
  return messaging
    .getToken({ vapidKey:FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
      updateNotificationState();
    });
  });

// messaging.onBackgroundMessage((payload) => {

//   console.log(payload,"payload self")
// });

// messaging.onBackgroundMessage((payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here

// });
