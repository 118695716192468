import { Row, Col } from "antd";
import AttachmentSessionList from "./AttachmentSessionList/AttachmentSessionList";
import { getAlignerNumberBackground } from "utilities/IPR";
import { showTeethName } from "components/shared/TeethChart/data";

const AttachmentInfo = ({
  alignersLabel,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  chartValue
}) => {
  return (
    <div className="attachmentInfo-container">
      <p
        className="patient-question"
        style={{ marginTop: "0px", textAlign: "left" }}
      >
        Attachment Details
      </p>
      {upperAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row gutter={[16, 16]} align="middle">
                      <Col lg={4} span={4}>
                        <p
                          style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `,
                          }}
                          className={`toothName`}
                        >
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(
                                attachmentValue.teeth,
                                chartValue,
                                "upper"
                              )}
                        </p>
                      </Col>
                      <Col lg={8} span={8}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType}
                        </p>
                      </Col>
                      <Col lg={12} span={12}>
                        <p className="attachment-response">
                          {attachmentValue.detail
                            ? attachmentValue?.detail
                            : "--"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
      {lowerAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row gutter={[16, 16]} align="middle">
                      <Col lg={4} span={4}>
                        <p
                          style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `,
                          }}
                          className={`toothName`}
                        >
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(
                                attachmentValue.teeth,
                                chartValue,
                                "lower"
                              )}
                        </p>
                      </Col>
                      <Col lg={8} span={8}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType}
                        </p>
                      </Col>
                      <Col lg={12} span={12}>
                        <p className="attachment-response">
                          {attachmentValue.detail
                            ? attachmentValue?.detail
                            : "--"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentInfo;
