import { useState, useEffect, useRef } from "react";
import { UserOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Table, Tag, Input, Row, Col, Checkbox, Pagination, Tooltip,Dropdown, Menu,message } from "antd";
import moment from "moment";
import { useHistory, Link, useLocation } from "react-router-dom";
import TitleComponent from "../../shared/titleComponent";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { receivePaymentAppSelector } from "services/redux/selectors/clinicSelectors";
// import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import { connect } from "react-redux";
// import { SELECTION_NONE } from "antd/lib/table/hooks/useSelection";
import FilterDataRangePicker from "../../shared/filterDataRangePicker";
import { ShowTotalNumber } from "components/shared/ShowTotalNumber/index";
import { ReactComponent as ToothXrayOne } from "../../../assets/images/toothXrayOne.svg";
import { ReactComponent as ToothXrayTwo } from "../../../assets/images/toothXrayTwo.svg";
import { logoColor, primaryColor, tagBackgroundColor } from "colors-config";
import { columnTitleElement, uniqByKeepLast } from "utilities/commonMethod";
import { showConfirmationModal } from "components/shared/ShowConfirmModal/index";
import { archivePatient } from "api/lab";
import AppTag from "components/shared/Tag/Tag";

// const { Search } = Input;
// const { Title } = Typography;

const ImpressionDecision = {
  Rejected: 0,
  Approved: 1,
  Received: 2,
};

const ImpressionDecisionText = {
  Rejected: "Rejected",
  Approved: "Approved",
  Received: "Image Received",
};

const PaymentStatusText = {
  AwaitingPayment: "Awaiting Payment",
  Paid: "Paid",
  Cancelled: "Cancelled",
  PartiallyPaid: "Partially Paid",
};

const PatientListComponent = ({
  clinic,
  patientsList,
  onSearch,
  paginationPage,
  paginationLimit,
  onPagination,
  totalRecords,
  handleDateFilter,
  searchLoading,
  fromDate,
  endDate,
  getPatients
}) => {
  const [
    tableData,
    setTableData,
    // pagination, defaultPageSize, pageSize, total, current,
  ] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [paginationObj, setPaginationObj] = useState({});
  const searchTimeRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const defaultSortOrder = queryParams.get("type") == "aligner-due" ? "descend" : undefined;

  const receiveAppPayment = useSelector(receivePaymentAppSelector);


  useEffect(() => {
    if (patientsList?.length >= 0) {
      setTableData(createTableData());
    }
    // getStatusFilter();
  }, [patientsList]);

  useEffect(() => {
    const obj = {
      current: paginationPage,
      showSizeChanger: true,
      // defaultCurrent: 1,
      // defaultPageSize: 50,
      pageSizeOptions: [5, 10, 20, 50],
      // size: "small",
      total: totalRecords,
      pageSize: paginationLimit,
    };
    setPaginationObj(obj);
  }, [paginationPage, paginationLimit, totalRecords, onSearch]);

  const getStatusFilter = () => {
    const status = [
      ...new Set(
        clinic.journeyStatus.journeyStatus?.map((data) => data.clinic_patient_journey_status_name)
      ),
    ];
    let data = [];
    status.forEach((value) => {
      data.push({
        text: value,
        value: value,
      });
    });
    return data;
    // setFilters(data);
  };

  const createTableData = () => {
    return patientsList.map((dataObj, index) => {
      let paymentStatus = " - ";
      if (dataObj?.payment_status_id) {
        const payment_status_id = dataObj.payment_status_id;
        if (payment_status_id == 1) paymentStatus = "Awaiting Payment";
        else if (payment_status_id == 2) paymentStatus = "Paid";
        else if (payment_status_id == 3) paymentStatus = "Cancelled";
        else paymentStatus = " - ";
      } else if (dataObj?.payment_status) {
        paymentStatus = dataObj?.payment_status;
      } else {
        paymentStatus = " - ";
      }
      if (dataObj?.payment_status == "Free" || dataObj?.payment_status == "Partially Paid") {
        paymentStatus = dataObj?.payment_status;
      }
      let firstSession = null;
      let secondSession = null;
      let sessionList = [];
      if (dataObj?.iprData?.length) {
        dataObj?.iprData.sort((a, b) => {
          return a.aligner_count - b.aligner_count;
        });
        const uniqueSessions = uniqByKeepLast(dataObj.iprData, (obj) => obj.aligner_count);
        sessionList = uniqueSessions;
        //  console.log("uniqueSessions",JSON.stringify(uniqueSessions));
        //  if(dataObj?.current_aligner_number){
        //   const getCurrentAlignerSession = uniqueSessions.find(obj=>obj.aligner_count==dataObj?.current_aligner_number);
        //   if(getCurrentAlignerSession){
        //     firstSession = getCurrentAlignerSession;
        //   }
        //   if(getCurrentAlignerSession?.completed){
        //     const findCompletedSessionIndex = uniqueSessions.findIndex(obj=>obj.aligner_count==dataObj?.current_aligner_number);
        //     if(findCompletedSessionIndex!=-1 &&  uniqueSessions[findCompletedSessionIndex+1]){
        //       secondSession =  uniqueSessions[findCompletedSessionIndex+1];
        //     }
        //   }
        //   if(!firstSession){
        //     firstSession=uniqueSessions[0]
        //   }
        //  }
        let first;
        for (let index = 0; index < uniqueSessions.length; index++) {
          const element = uniqueSessions[index];
          if (!element.completed) {
            first = element;
            break;
          }
        }
        if (first && uniqueSessions[0].aligner_count == first.aligner_count) {
          firstSession = uniqueSessions[0];
        } else {
          if (first) {
            const findFirstSessionIndex = uniqueSessions.findIndex(
              (obj) => obj.aligner_count == first.aligner_count
            );
            firstSession = uniqueSessions[findFirstSessionIndex - 1];
            secondSession = first;
          }
        }
      }

      return {
        key: index,
        profileCreatedDate: dataObj.patient_onboarded_on
          ? moment(dataObj.patient_onboarded_on).format("DD/MM/YYYY")
          : "Not Available",
        patientDetails: {
          patientID: dataObj.patient_id,
          patientName: dataObj.patient_first_name + " " + dataObj.patient_last_name,
        },
        treatmentType: dataObj.treatment_type
          ? dataObj.treatment_type + (dataObj.treatment_name ? ` - ${dataObj.treatment_name}` : "")
          : "Not Available",
        journeyStatus: dataObj.clinic_patient_journey_status_name
          ? dataObj.clinic_patient_journey_status_name
          : "Not available",
        impressionStatus: dataObj.impression_decision,
        paymentStatus: paymentStatus,
        iprSessions: {
          sessionNumber: "1",
          status: true,
        },
        alignerStage: {
          current_aligner_number: dataObj.current_aligner_number,
          max_aligner_count: dataObj.max_aligner_count,
          next_aligner_date: dataObj.next_aligner_date,
        },
        iprSession: {
          firstSession,
          secondSession,
          sessionList
        },
        consentForm:dataObj.consent_date ? dayjs(dataObj.consent_date).format("DD-MM-YYYY"):"-"
      };
    });
  };


  const patientDetails = (details) => {
    return (
      <>
        <Row><p style={{ fontWeight: '600', margin: '0px'}}>{details.patientName}</p></Row>
        <Row><p style={{ fontWeight: '300', margin:'0px'}}>{details.patientID}</p></Row>
      </>
    )
  }

  const onChange = (e) => {
    // e.preventDefault();
    e.stopPropagation();
  };
  const columns = [
    {
      title: columnTitleElement("PATIENT DETAILS"),
      dataIndex: "patientDetails",
      key: "patientDetails",
      fixed: "left",
      render: (data) => patientDetails(data)
      // width: 160,
    },
    // {
    //   title: columnTitleElement("PATIENT NAME"),
    //   dataIndex: "patientName",
    //   key: "patientName",
    //   fixed: "left",
    //   // width: 180,
    //   render: (patientName) => (
    //     <>
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <UserOutlined style={{ marginTop: "0px" }} />
    //         <p
    //           style={{
    //             fontWeight: "550",
    //             marginLeft: "0.3rem",
    //             marginBottom: 0,
    //             marginTop: "0px",
    //           }}
    //           color={"#DDFAFF"}
    //           key={patientName}
    //         >
    //           {patientName}
    //         </p>
    //       </div>
    //     </>
    //   ),
    // },

      //  width: 150,
    //   title: columnTitleElement("PATIENT NAME & ID"),
    //   dataIndex: "patientID",
    //   key: "patientID",
    //   // render: (id, record) => user(id, record.patientName),
    //   render: (id, record) => (
    //     <>
    //    <div>{record.patientName}</div>
    //    <div>{id}</div>
    //     </>
    //   ),
    // },
    {
      title: columnTitleElement("TREATMENT TYPE & NAME"),
      dataIndex: "treatmentType",
      key: "treatmentType",
      width: 150,
    }, 
    {
      title: columnTitleElement("PROFILE CREATED DATE"),
      dataIndex: "profileCreatedDate",
      key: "profileCreatedDate",
       width: 130,
       filtered: (fromDate && endDate) ? true : false,
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateFilter}
        />
      ),
    },
    {
      title: columnTitleElement("ALIGNER STAGE (CHANGE)"),
      key: "aligerStage",
      width: 160,
      dataIndex: "alignerStage",
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: defaultSortOrder,
      sorter: (a, b) =>
        new Date(a.alignerStage?.next_aligner_date) - new Date(b.alignerStage?.next_aligner_date),
      render: ({ current_aligner_number, max_aligner_count, next_aligner_date }) => {
        const currentAligner = current_aligner_number || "-";
        const maxAligner = max_aligner_count || "-";
        const nextDate = next_aligner_date
          ? `${dayjs(next_aligner_date).format("DD/MM/YYYY")}`
          : "";

        const isDatePassed = next_aligner_date
          ? dayjs().isBefore(next_aligner_date, "day")
            ? true
            : false
          : true;
        return (
          <div className="aligner-change-container">
            <Tag className="aligner">
              {`${currentAligner}/${maxAligner} ${
                clinic.companyType == 4 && currentAligner && currentAligner % 2 == 0 ? "Boost" : ""
              }`}
            </Tag>
            <span
              style={isDatePassed ? {} : { color: logoColor }}
              className={isDatePassed ? "date" : "date-passed"}
            >
              {nextDate}
            </span>
          </div>
        );
      },
    },
    {
      title: columnTitleElement("IPR & ATTACHMENT (STATUS)"),
      key: "iprSession",
      // width: 230,
      dataIndex: "iprSession",
      sortDirections: ["descend", "ascend", "descend"],
      render: (data) => {
        let firstSessionNo = 0;
        if (data?.firstSession) {
          data.sessionList.map((obj, index) => {
            if (obj.aligner_count == data.firstSession.aligner_count) {
              firstSessionNo = index + 1;
            }
          });
        }

        let secondSessionNo = 0;
        if (data?.secondSession) {
          data.sessionList.map((obj, index) => {
            if (obj.aligner_count == data.secondSession.aligner_count) {
              secondSessionNo = index + 1;
            }
          });
        }
        const dropdownRender = (menu) => (
          <div style={{ background: "#fff", padding: "10px 10px" }}>
            <Checkbox onChange={onChange}>Mark as Complete</Checkbox>
          </div>
        );
        let firstIprLabel = `IPR Session ${firstSessionNo}`;
        if (data?.firstSession?.type == "BOTH") {
          firstIprLabel = `Attachment Session ${firstSessionNo} & IPR Session ${firstSessionNo}`;
        } else if (data?.firstSession?.type == "ATTACHMENT") {
          firstIprLabel = `Attachment Session ${firstSessionNo}`;
        }

        let secondIprLabel = `IPR Session ${secondSessionNo}`;
        if (data?.secondSession?.type == "BOTH") {
          secondIprLabel = `Attachment Session ${secondSessionNo} & IPR Session ${secondSessionNo}`;
        } else if (data?.secondSession?.type == "ATTACHMENT") {
          secondIprLabel = `Attachment Session ${secondSessionNo}`;
        }

        return (
          <div
            className="aligner-change-container"
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            {data?.secondSession ? (
              data?.secondSession?.completed ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <ToothXrayTwo />
                  <p style={{ margin: "0" }}>{secondIprLabel}</p>
                </div>
              ) : (
                <div style={{ display: "flex", gap: "5px" }}>
                  <ToothXrayOne />
                  <p style={{ margin: "0" }}>{secondIprLabel}</p>
                </div>
              )
            ) : (
              ""
            )}
            {data?.firstSession ? (
              data?.firstSession?.completed ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <ToothXrayTwo />
                  <p style={{ margin: "0" }}>{firstIprLabel}</p>
                </div>
              ) : (
                <div style={{ display: "flex", gap: "5px" }}>
                  <ToothXrayOne />
                  <p style={{ margin: "0" }}>{firstIprLabel}</p>
                </div>
              )
            ) : (
              ""
            )}

            {!data?.firstSession && !data?.secondSession && data?.sessionList?.length
              ? "All Completed"
              : ""}
          </div>
        );
      },
      // sorter: (a, b) =>
      //   new Date(a.alignerStage?.next_aligner_date) -
      //   new Date(b.alignerStage?.next_aligner_date),
    },
    {
      title: columnTitleElement("JOURNEY STATUS"),
      key: "journeyStatus",
      dataIndex: "journeyStatus",
      // width: 260,
      align: "center",
      render: (journeyStatus) => (
          <AppTag style={{ borderRadius: "10px" }} key={journeyStatus} text={journeyStatus?.toUpperCase()}/>
      
      ),
      filters: getStatusFilter(),
      filterMultiple: true,
      // onFilter: (filterKey) => {
      //   debugger;
      // },
    },
    // {
    //   title: columnTitleElement("CONSENT FORM"),
    //   key: "consentForm",
    //   dataIndex: "consentForm",
    //   // sortDirections: ["descend", "ascend", "descend"],
    //   // sorter: (a, b) =>
    //   //   new Date(a.consentForm) -
    //   //   new Date(b.consentForm),
    //   fixed: "right",
    //   width: 150,
    //   render: (consentForm) => (
    //     <>
    //       <Tag style={{ borderRadius: "10px" }}>{consentForm}</Tag>
    //     </>
    //   ),
    // },
  ];


  if (receiveAppPayment) {
    const paymentStatusColumn = {
      title: columnTitleElement("PAYMENT STATUS"),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 140,
      filters: [
        {
          text: PaymentStatusText.AwaitingPayment,
          value: PaymentStatusText.AwaitingPayment,
        },
        { text: PaymentStatusText.Paid, value: PaymentStatusText.Paid },
        {
          text: PaymentStatusText.Cancelled,
          value: PaymentStatusText.Cancelled,
        },
        {
          text: PaymentStatusText.PartiallyPaid,
          value: PaymentStatusText.PartiallyPaid,
        },
      ],
      filterMultiple: true,
      render: (paymentStatus, record) => {
        return (
          <div>
            <AppTag key={record.key} text={paymentStatus} />
          </div>
        );
      },
    };
    columns.splice(2, 0, paymentStatusColumn);
  }
  

  if (clinic.companyType === 3) {
    const checkImpressionColumn = columns.find((obj) => obj.key == "impressionStatus");
    if (!checkImpressionColumn) {
      columns.push({
        title: columnTitleElement("IMPRESSION STATUS"),
        key: "impressionStatus",
        dataIndex: "impressionStatus",
        width: 200,
        render: (impressionStatus, index) => {
          return (
            <>
              <Tag style={{ borderRadius: "10px" }} key={index.key}>
                {impressionStatus === ImpressionDecision.Received &&
                  ImpressionDecisionText.Received}
                {impressionStatus === ImpressionDecision.Approved &&
                  ImpressionDecisionText.Approved}
                {impressionStatus === ImpressionDecision.Rejected &&
                  ImpressionDecisionText.Rejected}
                {!impressionStatus && impressionStatus !== 0 && "Not Uploaded"}
              </Tag>
            </>
          );
        },
        filters: [
          { text: ImpressionDecisionText.Received, value: "2" },
          { text: ImpressionDecisionText.Rejected, value: "0" },
          { text: ImpressionDecisionText.Approved, value: "1" },
        ],
        filterMultiple: true,
      });
    }
  }

  columns.push(
    {
      width: 80,
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (value, record) => {
        return (
            <Dropdown overlay={renderDropdownMenu(record)} trigger={["click"]}>
              <span onClick={(e) => e.stopPropagation()}>
                <MoreOutlined style={{ cursor: "pointer" }} />
              </span>
            </Dropdown>
        );
      },
    },
  )

  const onArchive = async (patientDetails) => {
    try {
      await archivePatient(patientDetails)
      await getPatients()
      message.success("Patient Archived Successfully")
    } catch (e) {
      message.error("Failed to archive patient. Please try again later");
    }
  }

  const renderDropdownMenu = (record) => (
    <Menu
      onClick={(e) => {
          e.domEvent.stopPropagation();
          showConfirmationModal(
            () => {
              const currentDate = dayjs();
              const archiveDate = currentDate.format("YYYY-MM-DD")
              const archiveTime = currentDate.format("HH:mm:ss")
              onArchive({
                patientId: record?.patientDetails?.patientID,
                from: "Clinic",
                archiveDate: archiveDate,
                archiveTime: archiveTime
              })
            },
            "Archive Patient",
            "Are you sure you want to archive this patient?",
            "Cancel", 
            "Proceed"
          );
      }}
    >
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
      >
        Archive
      </Menu.Item>
    </Menu>
  );

  const onSearchChange = (e) => {
    clearTimeout(searchTimeRef.current);
    const { value } = e.target;
    const trimmedValue = value?.trim();
    // if (trimmedValue) {
    searchTimeRef.current = setTimeout(() => {
      onSearch(trimmedValue);
    }, 1100);
    // }
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <Space direction="vertical">
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{
            width: 200,
            marginLeft: 760,
            borderRadius: 15,
          }}
        />
      </Space> */}
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col span={18} style={{ textAlign: "start" }}>
          <div className="headerListPatient">
            <TitleComponent titleText={"Patients List"}></TitleComponent>
            {/* <div className="patientListLength">{tableData?.length}</div> */}
            {tableData?.length > 0 && (
              <ShowTotalNumber total={filterData && filterData.length !== 0 ? filterData.length : tableData.length} className={"patientListLength"} />
            )}
          </div>
        </Col>
        <Col span={6}>
          <Input.Search
            onSearch={(e) => onSearch(e.trim())}
            allowClear
            enterButton
            size="large"
            onChange={onSearchChange}
            addonAfter={null}
            className="searchBox"
            placeholder="Search Patient ID, email, name, phone, DOB and address"
            loading={searchLoading}
          />
        </Col>
      </Row>
        <Table
          id="test"
          className="patient-list-table"
          style={{ width: "100%", overflow: "hidden", marginTop: "0.5rem" }}
          columns={columns}
          scroll={{
            y: 800,
          }}
          pagination={paginationObj}
          onChange={onPagination}
          dataSource={filterData && filterData.length !== 0 ? filterData : tableData}
          // dataSource={data}
          onRow={(record, recordIndex) => ({
            onClick: (e) => {
              // e.preventDefault();
              // e.stopPropagation();
              // e.nativeEvent.stopPropagation();
              history.push(`/clinic/patient/${record?.patientDetails?.patientID}`);
            },
          })}
        />
          {(filterData?.length > 0 || tableData?.length > 0) && <div style={{ position: 'relative', display: 'block', marginTop : '-40px',width: 'fit-content', zIndex: 1 }}>
        <Row style={{ alignItems: 'center', alignSelf: 'flex-start'}}>
          <DeleteOutlined />
          <Link style={{ marginLeft: '5px', color: primaryColor}} to='/clinic/archivePatients'>Archived Patients</Link>
        </Row>
      </div>}
       
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(PatientListComponent);
