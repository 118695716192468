import AppTag from "../Tag/Tag";


export const treatmentPhasingTableColumn = [
  {
    title: "Phase#",
    key: "phaseNo",
    dataIndex: "phaseNo",
    width: "20%",
    render: (phaseNo, record) => (
      <AppTag text={`Phase ${record?.phaseNo}`} />
    )
  },
  {
    title: "Aligner Set",
    key: "alignerIds",
    dataIndex: "alignerIds",
    width: "20%",
  },
  {
    title: "Est. Date of Delivery",
    key: "deliveryDate",
    dataIndex: "deliveryDate",
    width: "30%",
  },
  {
    title: "Details",
    key: "details",
    dataIndex: "details",
    width: "30%",
  },
];
