import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./table.scss";
import { treatmentPhasingTableColumn } from "./columns";
import { phasingDataMapper } from "utilities/treatment";

const TreatmentPhasingTable = ({ phasingList }) => {
  const [phasingData, setPhasingData] = useState([]);

  useEffect(() => {
    const finalPhasing = phasingDataMapper(phasingList);
    setPhasingData(finalPhasing);
  }, [phasingList]);

  return (
    <div>
      {/* { 
      ( */}
        <Table
          className="phasing-table-bottom"
          columns={treatmentPhasingTableColumn}
          dataSource={phasingData}
          pagination={false}
          rowHoverable={false}
          size={"small"}
        />
    
    </div>
  );
};

export default TreatmentPhasingTable;
