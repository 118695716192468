import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import "../../../assets/styles/components/progressImageComponent.scss";
import { Row, message, Select, Image, Card, Spin , Divider} from "antd";
import { primaryColor } from "colors-config";
import { CheckOutlined } from "@ant-design/icons";
import DeleteConfirmationModal from "../../shared/modals/deleteConfirmationModal";
import UploadImage from "./uploadImageComponent";
import { DeleteOutlined } from "@ant-design/icons";
import { DownloadIcon } from "components/shared/Icon/index";
import { downloadS3File, getPatientResourceUploadStageAPI, patientResourceDeleteApi } from "api/patient";
import { formatImageView, sortPhotosByOrder } from "utilities/patientDetail";
import { getPreliminaryImagesApi } from "api/prescription";

function ProgressImageComponentNew(
  {
    patientId,
    caseId,
    selectedCaseId,
    myDivRef,
  }) {
  const [isStageDropdownLoading, setIsStageDropdownLoading] = useState(true);
  const [isProgressImagesSpinning, setIsProgressImagesSpinning] = useState(false);
  const [alignerDropdownValues, setAlignerDropdownValues] = useState([]);
  const [selectedUploadStage, setSelectedUploadStage] = useState();
  const [selectedUploadStageData, setSelectedUploadStageData] = useState();
  const [allProgressImages, setAllProgressImages] = useState([]);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null);
  const [currentTreatmentLabId, setCurrentTreatmentLabId] = useState(null);

  const [showImageDeleteConfirmationModel, setShowImageDeleteConfirmationModel] = useState(false);
  const [extraOralImages, setExtraOralImages] = useState([])
  const [intraOralImages, setIntraOralImages] = useState([])

  useEffect(() => {
    if (patientId && selectedCaseId) {
      getAlignerDropdownList()
    }
  }, [patientId, selectedCaseId])

  const getAlignerDropdownList = async () => {
    const params = { patientId };
    if (selectedCaseId) params.caseId = selectedCaseId;
    try {
      setIsStageDropdownLoading(true);
      setIsProgressImagesSpinning(true);

      const res = await getPatientResourceUploadStageAPI(params);
      generateDropDownList(res?.body);
      setIsStageDropdownLoading(false);
      return res;
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsStageDropdownLoading(false);
    }
  };

  const generateDropDownList = (data) => {
    let isFirstCurrent = !data?.currentTreatmentId ? true : false;
    setCurrentTreatmentLabId(data?.currentTreatmentId);
    let options = [{
      label: <span className="progress-drd-item"><span>Preliminary Stage</span> <span>{isFirstCurrent ? <CheckOutlined /> : null}</span></span>,  
      value: "100",
      data: data?.patient_image_upload_stages.find(obj => obj.patient_image_uploaded_stage_id == 100)
    }];
    if (data?.new_patient_image_upload_stages?.length) {
      const groupedData = data?.new_patient_image_upload_stages.reduce((acc, item) => {
        const { labTreatmentId } = item;

        // Initialize array for each labTreatmentId if not exists
        if (!acc[labTreatmentId]) {
          acc[labTreatmentId] = [];
        }

        // Add the current item to the respective group
        acc[labTreatmentId].push(item);

        return acc;
      }, {});

      // const optionsList = Object.values()
      for (const [key, value] of Object.entries(groupedData)) {
        let newOption = {
          label: value[0].revisionNo ? `Refinement Plan ${value[0].revisionNo}` : `Treatment Plan ${value[0].treatmentNo}`,
          options: []
        };
        value.forEach(obj => {
          let isCurrent = false;
          if (obj?.labTreatmentId == data?.currentTreatmentId && obj?.patient_image_uploaded_stage_id == data?.current_stage) {
            isCurrent = true;
          }
          newOption.options.push({
            label: <span className="progress-drd-item"><span>{obj.patient_image_uploaded_stage_name}</span> <span>{isCurrent ? <CheckOutlined /> : null}</span></span>,
            value: `${obj.patient_image_uploaded_stage_id}:${obj.labTreatmentId}`,
            data: obj
          })
        })
        options.push(newOption);
      }
    }
    setAlignerDropdownValues(options);
    if (data?.currentTreatmentId) {
      setSelectedUploadStage(`${data.current_stage}:${data.currentTreatmentId}`)
      setSelectedUploadStageData(data?.new_patient_image_upload_stages?.find(obj => obj.patient_image_uploaded_stage_id == data?.current_stage && obj.labTreatmentId == data?.currentTreatmentId))

    }
    else {
      setSelectedUploadStage(data?.current_stage?.toString())
      setSelectedUploadStageData(data?.patient_image_upload_stages.find(obj => obj.patient_image_uploaded_stage_id == 100))

    }
  }

  useEffect(() => {
    if (selectedUploadStageData) {
      getProgressImages(selectedUploadStageData)
    }
  }, [selectedUploadStageData])

 
  const onDropdownChange = (value, option) => {
    setSelectedUploadStage(value);
    setSelectedUploadStageData(option?.data)
  }

  const getProgressImages = async (imageStageData = selectedUploadStageData) => {
    try {
      setIsProgressImagesSpinning(true)
      const request = {
        patientId,
        patientStage: "preliminary",
        alignerId: 100,
      }

      if (imageStageData?.patient_image_uploaded_stage_id == 100) {
        request.patientStage = "preliminary";
        request.alignerId = 100;
      }
      else if (imageStageData?.patient_image_uploaded_stage_id >= 1 && imageStageData?.patient_image_uploaded_stage_id <= 99) {
        request.patientStage = "aligner";
        request.alignerId = imageStageData?.patient_image_uploaded_stage_id;
        request.labTreatmentId = imageStageData?.labTreatmentId
      }

      const { data } = await getPreliminaryImagesApi(request)
      
      let patientNotUploadedImages = data?.body?.results?.progress_img_types_not_uploaded ? data?.body?.results?.progress_img_types_not_uploaded : [];
      const patientUploadedImages = data?.body?.results?.patient_onboarding_images ? data?.body?.results?.patient_onboarding_images : [];
      patientNotUploadedImages = patientNotUploadedImages.map(obj=>{
        if(imageStageData?.labTreatmentId){
          obj.lab_treatment_id = imageStageData?.labTreatmentId
        }
        return obj;
      })
      const combined = [...patientNotUploadedImages, ...patientUploadedImages];
      // console.log("combinedcombinedcombined", combined)
      const finalData = sortPhotosByOrder(combined);
      const firstThreeItems = finalData.slice(0, 3);
     // Get the rest of the items
       const restItems = finalData.slice(3);
       setExtraOralImages(firstThreeItems)
       setIntraOralImages(restItems)
      // console.log("finalDatafinalData", finalData)
       if (selectedUploadStageData?.patient_image_uploaded_stage_id !== 100) {
        setAllProgressImages(finalData);
       }

    } catch (error) {
      console.log("error", error);
    }
    finally {
      setIsProgressImagesSpinning(false)
    }
  }

  const renderTreatmentDropdown = () => {
    return (<Select
      value={selectedUploadStage}
      loading={isStageDropdownLoading}
      style={{
        width: 200,
      }}
      options={alignerDropdownValues}
      onSelect={onDropdownChange}
    />)
  }

  const download = async (file) => {
    if (file?.resource_url) {
      const fileName = file.resource_url?.split("/")[1];
      if (fileName) {
        window.open(downloadS3File(fileName));
      }
    }
  };

  const deleteImage = () => {
    deleteProgressImage(imageToBeDeleted.resource_url);
    setImageToBeDeleted(null);
  };


  const deleteProgressImage = async (resourceUrl) => {
    const hide = message.loading("Deleting image", 0);

    try {
      await patientResourceDeleteApi({
        patient_id: patientId,
        file_name: resourceUrl,
      })
      setTimeout(hide, 0);
      getProgressImages()
      message.success("Resource Deleted");
    } catch (error) {
      setTimeout(hide, 0);
      console.error(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to delete. Please try again later");
      }
    }
  };    
  const renderSection = () => {
    const renderImages = (images) =>
      images.map((item, i) => {
        const withOutAccessKeyImg = item?.url?.indexOf("?");
        const processedImg = item?.url?.slice(0, withOutAccessKeyImg);
        let currentTreatment = false;
  
        if (
          item?.lab_treatment_id === currentTreatmentLabId &&
          selectedUploadStageData?.patient_image_uploaded_stage_id !== 100
        ) {
          currentTreatment = true;
        }
  
        // if (
        //   item?.lab_treatment_id !== currentTreatmentLabId &&
        //   !item.url &&
        //   selectedUploadStageData?.patient_image_uploaded_stage_id !== 100
        // ) {
        //   return null;
        // }
  
        return (
          <div key={i}>
            {item.url ? (
              <div>
                <Row>
                  <p className="image-name">{formatImageView(item.patient_image_view)}</p>
                  {currentTreatment ||
                  selectedUploadStageData?.patient_image_uploaded_stage_id === 100 ? (
                    <DeleteOutlined
                      style={{
                        color: "red",
                        marginLeft: "0.5rem",
                        marginTop: "0.2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setImageToBeDeleted(item);
                        setShowImageDeleteConfirmationModel(true);
                      }}
                    />
                  ) : null}
                  <DownloadIcon
                    marginLeft={"0.5rem"}
                    onClick={() => {
                      download(item);
                    }}
                  />
                </Row>
                <Image className="image-dim" src={processedImg} />
              </div>
            ) : (
              <div>
                <p className="image-name">{formatImageView(item.patient_image_view)}</p>
                <UploadImage
                  patientId={patientId}
                  viewType={item.patient_image_view}
                  selectedUploadStageData={selectedUploadStageData}
                  caseId={caseId}
                  selectedCaseId={selectedCaseId}
                  getProgressImages={getProgressImages}
                />
              </div>
            )}
          </div>
        );
      });
  
    if (selectedUploadStageData?.patient_image_uploaded_stage_id === 100) {
      return (
        <>
          <h1>Intra Oral Images</h1>
          <div className="image-upload-container">{renderImages(intraOralImages)}</div>
          <Divider />
          <h1>Extra Oral Images</h1>
          <div className="image-upload-container">{renderImages(extraOralImages)}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="image-upload-container">{renderImages(allProgressImages)}</div>
        </>
      );
    }
  };
  

  return (
    <div style={{ height: "100%" }}>
      {showImageDeleteConfirmationModel && imageToBeDeleted && (
        <DeleteConfirmationModal
          title="Delete Image"
          content={`(${formatImageView(imageToBeDeleted?.patient_image_view)})`}
          showModal={showImageDeleteConfirmationModel}
          onConfirmation={deleteImage}
          setShow={setShowImageDeleteConfirmationModel}
          mode={"deleteImage"}
        />
      )}
      <Spin spinning={isProgressImagesSpinning}>
        <Card
          title="Progress Images"
          extra={renderTreatmentDropdown()}
          className={`progress-image-card`}
          bodyStyle={{ paddingRight: 0}}
        >
          <div
            className="preview-content"
            style={{
              maxHeight: `${myDivRef?.current?.clientHeight - 100}px`,
              overflowY: "auto",
              padding: '10px'
            }}
          >      
            {renderSection()} 
        
          </div>
        </Card>
      </Spin>
    </div>
  );
}

export default forwardRef(ProgressImageComponentNew);
