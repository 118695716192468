import { createSelector } from "reselect";

const getClinic = (state) => state.clinic;

export const clinicIdSelector = createSelector(
  getClinic,
  (clinic) => clinic.clinicId
);

export const clinicSelector = createSelector(getClinic, (clinic) => {
  return clinic;
});

export const getCountries = createSelector(
  getClinic,
  (clinic) => clinic?.commonData?.countries
);
export const getPatientCountryCodesList = createSelector(
  getClinic,
  (clinic) => clinic.patientCountryCodesList
);
export const getCountryCodes = createSelector(
  getClinic,
  (clinic) => clinic.countryCodes
);

export const clinicAddress = createSelector(
  getClinic,
  (clinic) => clinic.multipleAddresses
);

export const clinicDefaultAddress = createSelector(clinicAddress, (address) => {
  let defaultAddress = null;

  address.forEach((el) => {
    if (el.default) {
      defaultAddress = { ...el };
    }
  });

  if (!defaultAddress) {
    defaultAddress = address[0];
  }
  return defaultAddress;
});

export const isOnboardedSelector = createSelector(
  getClinic,
  (clinic) => clinic.isOnboarded
);


export const getClinicAvailability = createSelector(
  getClinic,
  (clinic) => clinic.clinicAvailability
);

export const getClinicProfileSelector = createSelector(
  getClinic,
  (clinic) => clinic.clinicProfile
);

export const getClinicSettingsLoaderSelector = createSelector(
  getClinic,
  (clinic) => clinic.clinicSettingsLoader
);


export const getClinicPartnerSelector = createSelector(
  getClinic,
  (clinic) => clinic.partner
);


export const getClinicManagementSelector = createSelector(
  getClinic,
  (clinic) => clinic.clinicManagement
);


export const getClinicTreatmentProductsSelector = createSelector(
  getClinic,
  (clinic) => clinic.products || []
);
export const getClinicCurrencySelector = createSelector(
  getClinic,
  (clinic) => clinic?.commonData?.currencyList
);


export const getClinicProductTypesSelector = createSelector(
  getClinic,
  (clinic) => clinic?.commonData?.productTypes || []
); 

export const getClinicMeetingDurationListSelector = createSelector(
  getClinic,
  (clinic) => clinic?.commonData?.meetingDurationList || []
);

export const getClinicAppointmentsSelector = createSelector(
  getClinic,
  (clinic) => clinic.appointments
);

export const getClinicAppointmentTypesSelector = createSelector(
  getClinic,
  (clinic) => clinic?.commonData?.appointmentTypes || []
);

export const isClinicAvailability = createSelector(
  getClinic,
  (clinic) => !(clinic.appointments && clinic.appointments.availabilityList)
);

export const isClinicProducts = createSelector(
  getClinic,
  (clinic) => (clinic.products && clinic.products.length < 1)
);


export const isClinicPayments = createSelector(
  getClinic,
  (clinic) => !(clinic.payments && clinic.payments.length > 2)
);

export const clinicPayments = createSelector(
  getClinic,
  (clinic) => clinic.payments
)


export const receivePaymentAppSelector = createSelector(
  getClinic,
  (clinic) => clinic?.clinicProfile?.receiveAppPayment
);


export const getDoctorAppInfoSelector = createSelector(
  getClinic,
  (clinic) => clinic?.commonData?.doctorAppOptions
);

