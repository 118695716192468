const Header = () => {
  return (
    <div className="doctor-modal-header">
      <p>
        Upgrade Your Practice with Our
        <span> New Doctor App!</span>
      </p>
    </div>
  );
};

export default Header;
