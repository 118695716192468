import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TreatmentPDF from "components/private/TreatmentPDF/TreatmentPDF";
import { getClinicProfileService, getPatientDetailsApi, getTreatmentOldAPI } from "api/patient";
import { dataMapper, generateAlignerLabels } from "utilities/IPR";
import { uniqByKeepLast } from "utilities/commonMethod";
import { getClinicDetails } from "api/clinic";


export default function ViewTreatmentGuide() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const patientId = queryParams.get("patientId");
    const treatmentId = queryParams.get("treatmentId");
    const caseId = queryParams.get("caseId");
    const chartValue = queryParams.get("chartValue");
    const [upperAligners, setUpperAligners] = useState([]);
    const [lowerAligners, setLowerAligners] = useState([]);
    const [alignersLabel, setAlignersLabel] = useState([]);
    const [lowerAttachments, setLowerAttachments] = useState([]);
    const [upperAttachments, setUpperAttachments] = useState([]);
    const [attachmentsLabel, setAttachmentsLabel] = useState([]);
    const [treatment, setTreatment] = useState(null);
    const [clinic, setClinic] = useState(null);
    const [patientInfo, setPatientInfo] = useState(null);

    const clinicMapper = (clinic) => {
        return {
            clinicId : clinic?.clinicProfile?.clinic_id,
            clinicName : clinic?.clinicProfile?.clinic_name,
            clinicEmail : clinic?.clinicProfile?.clinic_email,
            clinicCountryCode : clinic?.clinicProfile?.country_code_name,
            clinicPhoneNumber: clinic?.clinicProfile?.clinic_contact,
            multipleAddresses: clinic?.clinicManagement
        }
    }

    const loadData = async () => {
        try {
            const model = { patientId: patientId, caseId: caseId };
            const treatmentResponse = await getTreatmentOldAPI(model);
            const clinic_id = treatmentResponse?.body?.treatment_setup[0]?.clinic_id;
            const patientDetails = await getPatientDetailsApi(patientId);
            const clinicDetails = await getClinicProfileService(clinic_id);
            const clinicInfo  = clinicMapper(clinicDetails?.data?.data)
            const treatmentData = treatmentResponse?.body?.treatment_setup?.find(data => data.lab_treatment_id === treatmentId)
            setPatientInfo(patientDetails.data?.body[0]);
            setClinic(clinicInfo);
            setTreatment(treatmentData)
        } catch (e) {
            console.log('error', e);
        }
    }
    useEffect(() => {
        if (patientId, caseId) {
            loadData(patientId, caseId);
        }
    }, [patientId, caseId])

    useEffect(() => {
        if (treatment?.iprData?.length) {
            let apiIprData = treatment.iprData;
            if (treatment.revision_plan_version) {
                apiIprData = apiIprData.filter(
                    (obj) => obj.refinement && treatment.lab_treatment_id == obj.labTreatmentId
                );
            } else {
                apiIprData = apiIprData.filter((obj) => !obj.refinement);
            }
            const { lower, upper } = dataMapper(apiIprData);
            setUpperAligners(upper.reverse());
            setLowerAligners(lower);
            const iprData = apiIprData;
            iprData.sort((a, b) => {
                return a.aligner_count - b.aligner_count;
            });
            let uniqueSessions = uniqByKeepLast(iprData, (obj) => obj.aligner_count);
            // uniqueSessions = uniqueSessions.map((obj) => {
            //   obj.checked = true;
            //   return obj;
            // });
            // setAlignersLabel(uniqueSessions);
            const alignerLabel = generateAlignerLabels(uniqueSessions, treatment.num_of_upper_aligners, treatment.num_of_lower_aligners);
            setAlignersLabel(alignerLabel);
        }
    }, [treatment]);


    useEffect(() => {
        if (treatment?.attachmentData?.length) {
            let apiAttachmentData = treatment.attachmentData;
            if (treatment.revision_plan_version) {
                apiAttachmentData = apiAttachmentData.filter(
                    (obj) => obj.refinement && treatment.lab_treatment_id == obj.labTreatmentId
                );
            } else {
                apiAttachmentData = apiAttachmentData.filter((obj) => !obj.refinement);
            }

            const { lower, upper } = dataMapper(apiAttachmentData);
            setUpperAttachments(upper.reverse());
            setLowerAttachments(lower);
            const attachmentData = apiAttachmentData;
            attachmentData.sort((a, b) => {
                return a.aligner_count - b.aligner_count;
            });
            let uniqueSessions = uniqByKeepLast(attachmentData, (obj) => obj.aligner_count);
            // uniqueSessions = uniqueSessions.map((obj, index) => {
            //   obj.checked = true;
            //   obj.session = index + 1;
            //   return obj;
            // });    
            // setAttachmentsLabel(uniqueSessions);
            const alignerLabel = generateAlignerLabels(uniqueSessions, treatment.num_of_upper_aligners, treatment.num_of_lower_aligners);
            setAttachmentsLabel(alignerLabel);
        }
    }, [treatment]);

    function isSafari() {
        const ua = navigator.userAgent.toLowerCase();
        return (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
    }

    const getPageStyles = () => {
        return `
          @media print {
           
            @page {
              margin: 0 !important;
               padding: 0 !important;
               size: A4;
            }
              
            .teethChart-container .teethRow-container .teethRow-one svg {
              width: 38px !important;
            }
    
            .ipr-session-list-labels{
            margin-left:auto;}
            
    
              ${isSafari() ? 
    
                `
                 html, body {
           margin: 0 !important;
               padding: 0 !important;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
          .aligner-component .ipr-check-row{
              display:flex;}
           
        
      `:''
              }
          }
        `;
      };

    return (
        <div >
            <style>{getPageStyles()}</style>
            <TreatmentPDF
                clinic={clinic}
                patientInfo={patientInfo}
                treatment={treatment}
                lowerAligners={lowerAligners}
                upperAligners={upperAligners}
                chartValue={chartValue}
                upperAttachments={upperAttachments}
                lowerAttachments={lowerAttachments}
                alignersLabel={alignersLabel}
                attachmentsLabel={attachmentsLabel}
            //   onAlignerLabelClicked={onAttachmentsLabelClicked}
            />
        </div>)
}