import api from "./api";

export const refinementAdditionalDetailsService = (data) => {
    return api({
      method: "POST",
      url: "/lab/refinement/additional-details",
      data,
    }).then((res) => res.data);
  };
  
  export const uploadRefinementFileService = (data) => {
    return api({
      method: "POST",
      url: "/lab/refinement/file/upload",
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  export const savePhaseOrder = data => {
    return api({
      method: "POST",
      url: `/clinic/phase-order`,
      data
    });
  };