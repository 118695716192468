import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Popconfirm,
  Button,
  Radio,
  message,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import { getPatientCountryCodesList } from "services/redux/selectors/clinicSelectors";
import FormFooter from "../../../shared/FormFooter/FormFooter";
import styles from "./management.module.scss";
import { updateClinicManagementAction } from "services/redux/actions/clinicActions";
import { mapClinicManagementData } from "utilities/settings";
import { updateClinicManagementAPI } from "api/settingsApi";
import ConsentForm from "../ConsentForm/ConsentForm";
import mixpanel from 'mixpanel-browser';

const ManagementForm = ({ setEditAddress, clinicManagement, clinicId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [managementForm] = Form.useForm();
  const countryCodes = useSelector(getPatientCountryCodesList);

  useEffect(() => {
    // console.log("clinicProfile", clinicProfile);
    const formData = mapClinicManagementData(clinicManagement);
    const finalObj = {
      clinicAddress: formData,
    };
    // console.log("formData", formData);
    managementForm.setFieldsValue(finalObj);
  }, [clinicManagement, managementForm]);

  const onFinish = async (values) => {
    // console.log("Values", values);
    const { clinicAddress } = values;
    const payload = clinicAddress.map(
      ({ clinicId, createdAt, id, updatedAt, ...rest }) => ({
        ...rest,
        default: rest.default ? 1 : 0,
        id: id ? id : `(New)${uuidv4()}`
      })
    );
    try {
      setLoading(true);
      const { data } = await updateClinicManagementAPI(payload, clinicId);

      mixpanel.people.set({
        clinicDetails:data.data,
      });
      
      message.success("Clinic addresses updated");
      // console.log("clinicAddressclinicAddress", clinicAddress);
      dispatch(updateClinicManagementAction(data.data));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setEditAddress(false);
    }
  };

  const onCancel = () => {
    setEditAddress(false);
  };
  const onOk = () => {
    managementForm.submit();
  };

  const removeHandleChange = (value, name) => {
    const values = managementForm.getFieldsValue().clinicAddress || [];
    let updatedValues = values;
    if (values.length > 0 && !values.some(it => it.default)) {
      updatedValues = updatedValues.map((el, i) => {
        if (i === 0) {
          return {
            ...el,
            default: true
          }
        }
        return el
      })
      managementForm.setFieldsValue({ clinicAddress: updatedValues });
    }
  }

  const handleCountryNameChange = (value, name) => { };

  const handleRadioChange = (name) => {
    const values = managementForm.getFieldsValue().clinicAddress || [];
    let updatedValues;
    if (values.length === 1) {
      updatedValues = values.map((item, i) => ({
        ...item,
        default: true,
      }));
    } else {
      updatedValues = values.map((item, i) => ({
        ...item,
        default: i === name,
      }));
    }
    managementForm.setFieldsValue({ clinicAddress: updatedValues });
  };

  return (
    <div >
      <div className={styles["management-form-container"]} >
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className={styles["heading"]}>Clinic Management</h2>
          </Col>
        </Row>
        <p className={styles["form-label"]}>Clinician Name</p>
        <Form
          form={managementForm}
          name="dynamic_form"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.List name="clinicAddress">
            {(fields, { add, remove }) => (
              <>
                <div style={{ padding: "10px 15px" }}>
                  {fields.map(({ key, name, fieldKey, ...restField }) => {
                    const IsPrimary = managementForm.getFieldValue([
                      "clinicAddress",
                      name,
                      "default",
                    ]);

                    return (
                      <div key={key}>
                        <Row gutter={[32, 32]} align="middle">
                          <Col lg={6}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "label"]}
                              fieldKey={[fieldKey, "label"]}
                              label=""
                              rules={[
                                {
                                  required: true,
                                  message: "Clinic name is required!",
                                },
                              ]}
                            >
                              <Input placeholder="clinic Name" size="large" />
                            </Form.Item>
                          </Col>
                          <Col lg={10}>
                            <Form.Item
                              {...restField}
                              name={[name, "address"]}
                              fieldKey={[fieldKey, "address"]}
                              label=""
                              className={styles["required-input"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Address!",
                                  validator(_, value) {
                                    if (value && value.trim().length !== 0) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      "Address should be valid!"
                                    );
                                  },
                                },
                              ]}
                            >
                              <Input size={"large"} placeholder="Address" />
                            </Form.Item>
                          </Col>
                          <Col lg={1}>
                            {fields.length > 1 && (
                              <Popconfirm
                                title="Delete"
                                description="Are you sure you want to delete this Address?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e) => {
                                  remove(name);
                                  removeHandleChange(name);
                                }}
                                onCancel={() => { }}
                              >
                                <DeleteOutlined
                                  style={{
                                    marginTop: -10,
                                    fontSize: "18px",
                                    float: "right",
                                    color: "#77849E",

                                  }}
                                />
                              </Popconfirm>
                            )}
                          </Col>
                          <Col lg={7}>
                            <Form.Item
                              {...restField}
                              name={[name, "default"]}
                              fieldKey={[fieldKey, "default"]}
                              label=""
                              valuePropName="checked"
                            >
                              <div className={styles["primary-radio"]}>
                                {fieldKey === 0 && (
                                  <span className={styles["radio-label"]}>
                                    Primary Clinic
                                  </span>
                                )}
                                <Radio
                                  onChange={() => handleRadioChange(name)}
                                  checked={IsPrimary}
                                />
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[32, 32]} align="middle">
                          <Col lg={5} offset={6}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "city"]}
                              fieldKey={[fieldKey, "city"]}
                              label=""
                              rules={[
                                { required: true, message: "City is required" },
                                {
                                  max: 30,
                                  message:
                                    "City name cannot be more than 30 characters",
                                },
                              ]}
                            >
                              <Input size={"large"} placeholder="City" />
                            </Form.Item>
                          </Col>
                          <Col lg={5}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "county"]}
                              fieldKey={[fieldKey, "county"]}
                              label=""
                              rules={[
                                {
                                  required: true,
                                  max: 30,
                                  validator(_, value) {
                                    const regex =
                                      /^([a-zA-Z0-9 \u0080-\u024F]+(?:. |-| |'))*[a-zA-Z0-9 \u0080-\u024F]*$/;
                                    if (value && value.length >= 30) {
                                      return Promise.reject(
                                        "County/Province/State name cannot be more than 30 characters"
                                      );
                                    }
                                    if (!value) {
                                      return Promise.reject(
                                        "County/Province/State name is required"
                                      );
                                    }
                                    if (regex.test(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        "Invalid county name"
                                      );
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder="County/Province/State"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[32, 32]} align="middle">
                          <Col lg={5} offset={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "zipCode"]}
                              fieldKey={[fieldKey, "zipCode"]}
                              label=""
                            >
                              <Input
                                size={"large"}
                                placeholder="Post/Zip/EIR Code"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={5}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "country"]}
                              fieldKey={[fieldKey, "country"]}
                              label=""
                              rules={[
                                {
                                  required: true,
                                  message: "Country is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                size="large"
                                placeholder="Country"
                                style={{ width: "100%", textAlign: "left" }}
                                options={countryCodes?.map((country, i) => ({
                                  value: country.name,
                                  label: (
                                    <>
                                      <span className="emoji-font">
                                        {country.emoji}
                                      </span>
                                      {` ${country.name}`}
                                    </>
                                  ),
                                  key: i,
                                }))}
                                onChange={(value) =>
                                  handleCountryNameChange(value, name, fieldKey)
                                }
                                filterOption={(input, option) =>
                                  option.value
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    );
                  })}
                  <Row>
                    <Col offset={14}>
                      <Button
                        shape="round"
                        style={{ width: "160px" }}
                        className="addOn-btn"
                        onClick={() => {
                          add();
                        }}
                      >
                        Add Another
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Form.List>
        </Form>
        <ConsentForm
      mode={"edit"}
      />
      </div>
      <FormFooter onCancelClick={onCancel} onOkClick={onOk} loading={loading} />
    </div>
  );
};

export default ManagementForm;
