import { Row, Col } from "antd";
import { ReactComponent as ScanPhone } from "assets/images/ScanPhone.svg";
import { ReactComponent as PlayStoreIcon } from "assets/images/PlayStoreIcon.svg";
import { ReactComponent as AppStoreIcon } from "assets/images/AppStoreIcon.svg";
import { iosStoreUrl, playStoreUrl, ScanImageUrl } from "colors-config";
const ScanApp = () => {
  return (
    <div>
      <Row gutter={[16, 16]} justify="space-between" align="top">
        <Col lg={12}>
          <div className="features-list">
            <h2 className="title">
              Approve or Modify case directly from the App
            </h2>
            <p className="description">
              Stay updated with instant notifications, even when you're away.
            </p>
            <h2 className="title">Seamless Communication</h2>
            <p className="description">
              Connect with patients and labs through built-in messaging.
            </p>
            <h2 className="title">Quick Patient Addition</h2>
            <p className="description">
              Easily add new patients directly from your mobile phone.
            </p>
            <h2 className="title">Progress Images</h2>
            <p className="description">
              View patient progress images on the go, no need to access your
              computer.
            </p>
          </div>
          <div className="qr-scans">
            <div className="store-icon">
              <a href={iosStoreUrl} target="_blank" rel="noreferrer">
                <AppStoreIcon />
              </a>
            </div>
            <div className="store-icon">
              <a href={playStoreUrl} target="_blank" rel="noreferrer">
                <PlayStoreIcon />
              </a>
            </div>
            <div className="qr-scan">
              <img src={ScanImageUrl} alt="app Qr" />
            </div>
          </div>

          <p className="description">
            Scan the QR code to download or find it in your app store
          </p>
        </Col>

        <Col lg={12}>
          <ScanPhone style={{ width: "100%", height: "400px" }} />
        </Col>
      </Row>
    </div>
  );
};

export default ScanApp;
