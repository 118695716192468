import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import UploadFile from "components/shared/ScanUpload/ScanUpload";
import { Row, Col, message,Popconfirm } from "antd";
import { scansArray } from "utilities/Patient";
import "./scanFile.scss"
import { determineMimeType, getScanFileImageType } from "utilities/files";
import { v4 as uuidv4 } from "uuid";
import { capitalizeWord } from "utilities/commonMethod";
import { generateSignedUrlAPI, uploadFileToS3API } from "api/scans";
import ScanFileIcon from "components/shared/Icon/ScanFileIcon";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { downloadS3File } from "api/patient";
import FileViewModal from "components/shared/FileViewModal/FileViewModal";
import { primaryColor } from "colors-config";


const ScanFiles = forwardRef((props, ref) => {
  const { setIsChanged, patientId, clinicId } = props;
  const [scanFiles, setScanFiles] = useState(scansArray);

  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeFile, setIframeFile] = useState(null);

  const [upperArch, setUpperArch] = useState([]);
  const [lowerArch, setLowerArch] = useState([]);
  const [biteScan1, setBiteScan1] = useState([]);
  const [biteScan2, setBiteScan2] = useState([]);

  useEffect(() => {
    if (upperArch.length || lowerArch.length || biteScan1.length || biteScan2.length) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [upperArch, lowerArch, biteScan1, biteScan2]);

  useImperativeHandle(ref, () => ({
    scanFiles: {
      upperArch,
      lowerArch,
      biteScan1,
      biteScan2,
    },
    scansArray: scansArray,
  }));

  const onChange =
    (id, file) => {

      let setter = null;

      if (id === 1) {
        setter = setUpperArch;
      }

      if (id === 2) {
        setter = setLowerArch;
      }
      if (id === 3) {
        setter = setBiteScan1;
      }
      if (id === 4) {
        setter = setBiteScan2;
      }

      setter(
        [file]
      );
    };


  const fileList = (id) => {
    if (id === 1) {
      return upperArch;
    }

    if (id === 2) {
      return lowerArch;
    }
    if (id === 3) {
      return biteScan1;
    }
    if (id === 4) {
      return biteScan2;
    }

    return [];
  };

  const updateScanFiles = (data, name) => {
    let tempScanFiles = [...scanFiles];
    tempScanFiles = tempScanFiles.map(obj => {
      if (obj?.name === name) {
        obj.file = data
      }
      return obj;
    });
    setScanFiles(tempScanFiles);
  }

  async function handleUpload({ file, onProgress, onSuccess, onError }, scanItem) {
    try {
      const originScanType = scanItem?.name;
      const tempScanType = capitalizeWord(originScanType);
      const scanType = tempScanType == 'BiteScan1' ? 'BiteScan' : getScanFileImageType(tempScanType)
      const contentType = file.type || determineMimeType(file.name, file);
      if (!contentType) {
        message.error(`Unsupported file type`);
        return onError(new Error("Unsupported file type"));
      }

      const fileNameArray = file.name.split(".");
      fileNameArray[0] =  uuidv4();
      const fileName = fileNameArray.join(".");

      const requestData = {
        fileName: fileName,
        contentType,
        imageView: scanType,
        patientId,
        type: "file",
        model: "refinement",
        createdFrom: "clinic",
        caseId: 1,
      };


      file.imageView = scanType;
      file.fileName = fileName;
      file.contentType = contentType

      const { data: url } = await generateSignedUrlAPI(clinicId, requestData);
      await uploadFileToS3API(url, file, contentType, onProgress);
      updateScanFiles(file, originScanType)
      onChange(scanItem.id, file)
      onSuccess("Upload successful");

      message.success(`${fileName} file uploaded successfully.`);
    } catch (error) {
      console.error("Upload failed:", error);
      onError(error);
      message.error(`File upload failed: ${error.message}`);
    }
  }
  const handleDelete = originScanType => {
    console.log("scanFiles",scanFiles);
    updateScanFiles(null, originScanType)
  };

  const downloadHandler = file => {
    window.open(downloadS3File(file?.name));
  };

  const fileClickHandler = file => {
    setIframeFile(file);
    setShowIframeModal(true);
  };

  const handleOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  const handleCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  return (
    <div className="scanFiles-refinement">
      <div style={{marginBottom:"1rem"}}> 
        <b>Scan Files</b>
      </div>
      <Row gutter={[32, 0]}>
        {scansArray.map((scanItem) => {
          const file = scanItem.file;
          return (
            <Col md={6} key={scanItem.id}>
              {file ?
                <div className="scan-file-display">
                  <ScanFileIcon
                    style={{ color: primaryColor }}
                    className="scan-file-icon"
                  />
                  <div className="fileMask">
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete this scan?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleDelete(scanItem?.name)}
                    >
                      <DeleteOutlined style={{ fontSize: "16px" }} />
                    </Popconfirm>
                    {file.url && (
                      <DownloadOutlined
                        className="downIcon"
                        style={{ margin: "0.5rem", fontSize: "16px" }}
                        onClick={() => downloadHandler(file)}
                      />
                    )}
                    {file.url && (
                      <EyeOutlined
                        className="viewIcon"
                        onClick={() => fileClickHandler(file)}
                        style={{ fontSize: "20px" }}
                      />
                    )}
                  </div>
                </div> :
                <UploadFile
                  // beforeUpload={beforeUpload}
                  // accept={".stl"}
                  // onChange={onChange(scanItem.id)}
                  multiple={false}
                  // fileList={fileList(scanItem.id)}
                  className="scan-file-wrapper"
                  // uploadFiles={({ file, onSuccess, onError }) => {
                  //   onSuccess(true);
                  // }}
                  uploadFiles={(event => handleUpload(event, scanItem))}
                  children={
                    <>
                      {" "}
                      <UploadOutlined style={{ fontSize: "12px" }} />
                      <p style={{ fontSize: "8px" }} className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </>
                  }
                />
              }
              
              <div className="subText">{scanItem.label}</div>

            </Col>
          );
        })}
      </Row>

      {showIframeModal && (
        <FileViewModal
          isModalVisible={showIframeModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          iframeFile={iframeFile}
        />
      )}
    </div>
  );
});

export default ScanFiles;
