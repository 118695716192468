import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, message, Select, Modal, InputNumber, Alert, Form, Checkbox, Divider, Dropdown, Menu, Button } from "antd";
import TextEditor from "components/shared/TextEditor/TextEditor";
import { primaryColor, companyType } from "colors-config";
// import { PATIENTCONSTANTS } from "utilities/patientDetail";
import { updatePatientTreatmentData } from "api/patient";
// import { ReactComponent as BulbIcon } from "../../../../assets/images/BulbIcon.svg";
import AppAlert from "components/shared/Alert/Alert";
import styles from "./approveSentToPatient.module.scss";
import { SupportToolTip } from "utilities/settings";
import DoctorAppModel from "components/shared/DoctorAppModel/DoctorAppModel";
import { PATIENTCONSTANTS } from "utilities/patientDetail";
import { approvalInfoMessage, detailInfoMessage, } from "messages/messages";
import TreatmentProductsTable from "components/shared/TreatmentProductsTable/TreatmentProductsTable";
import { updateClinicIntercomStatus } from "api/clinic";
import { useDispatch, useSelector } from "react-redux";
import { showOrderTourAfterSendToPatient } from "services/redux/actions/clinicActions";
import AppSettingsContext from "Context/useAppContext";
import { getDoctorAppInfoSelector } from "services/redux/selectors/clinicSelectors";
import CustomEditIcon from "components/shared/Icon/EditIcon";


const { Option } = Select;

const ApproveSentToPatient = ({
  isModalApproveSendPlanVisible,
  handleApproveSendPlanCancel,
  treatment,
  productList,
  treatmentProgressDet,
  productOrServiceCurrencyOptions,
  setIsModalApproveSendPlanVisible,
  confirmTreatmentStatus,
  patientId,
  clinic,
  wholeTreatmentPlan,
  fromShareWithPatient,
  setFromShareWithPatient,
  setSendOrderWithoutPatientApproval
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [netPrice, setNetPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [productCurrency, setProductCurrency] = useState("");
  const [approveSendPlanPopLoading, setApproveSendPlanPopLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [clinicProductFree, setClinicProductFree] = useState(false);
  const [patientApproval, setPatientApproval] = useState(false);
  const [isModalPatientApprovePlanVisible, setIsModalPatientApprovePlanVisible] = useState(false);
  const { clinicConfig } = useContext(AppSettingsContext);
  const [showScanModel, setShowScanModel] =useState(false); 
  const clinicDoctorAppInfoSelector = useSelector(getDoctorAppInfoSelector);
  const [openDropdown,setOpenDropDown] = useState(false)




  const getTotalPrice = () => {
    if (treatment?.labProductData?.quantityType == "Per aligner") {
      return (treatment?.labProductData?.price * treatment?.quantity).toFixed(2);
    }
    return treatment?.labProductData?.price.toFixed(2);
  };

  useEffect(() => {
    if (productList.length && treatmentProgressDet) {
      let productCurrency = {};
      const product = productList.find(
        (el) => el.product_name === treatmentProgressDet.treatment_name
      );
      if (product) {
        productCurrency = productOrServiceCurrencyOptions.find(
          (el) => el.currency_id === product.product_currency_id
        );
        const hideTreatmentInfo = Boolean(product.hidePatientPrice);
        form.setFieldValue("hideTreatmentInfo", hideTreatmentInfo);

        setDisableTPInfo(hideTreatmentInfo);
      }

      
      if (treatmentProgressDet?.treatment_name) {
        // console.log("productttttt", product)
        // console.log("treatmentProgressDettreatmentProgressDet", treatmentProgressDet)
        form.setFieldValue("treatmentName", product ? product.product_id : null);
      }
      if (product) {
        const { price, discount } = product;
        const discountedPrice = price - discount;
        const discountPercentage = discount
        ? ((discount / price) * 100).toFixed(2)
        : 0;
  
        form.setFieldsValue({ 
          netPrice: price - discount,
          newPrice: price, // Original price
          discountPercentage: discountPercentage, 
          discountAmount: discount, 
          discountPrice: discountedPrice.toFixed(2), 
          currency: product.currency_name,
          productFree: product.free,
        });
        setProductCurrency(product.currency_name)
        setClinicProductFree(product.free)
      } 
      // if (treatmentProgressDet?.arches_treated) {
      //   form.setFieldValue("arches", treatmentProgressDet?.arches_treated);
      // }

      if (typeof treatmentProgressDet?.treatment_price === "number") {
        let discountPercentage = 0;
        let dAmount = 0;
        let tAmount = 0;

        if (treatmentProgressDet.treatment_price !== 0 && treatmentProgressDet?.discount) {
          dAmount = treatmentProgressDet.discount;
          discountPercentage = getDiscountPercentage(
            treatmentProgressDet.treatment_price,
            treatmentProgressDet.discount
          );
          tAmount = treatmentProgressDet.treatment_price - treatmentProgressDet.discount;
        } else {
          discountPercentage = 0;
          dAmount = 0;
          tAmount = treatmentProgressDet.treatment_price;
        }
        setNetPrice(treatmentProgressDet.treatment_price);
        setDiscount(discountPercentage);
        setDiscountAmount(dAmount);
        setTotalAmount(tAmount);
        if (treatmentProgressDet.currency_name || productCurrency.currency_name) {
          form.setFieldValue(
            "price",
            treatmentProgressDet.currency_name
              ? `${treatmentProgressDet.currency_name} ${tAmount}`
              : `${productCurrency.currency_name} ${tAmount}`
          );
        }
        // form.setFieldValue("free", tAmount === 0);
        // setClinicProductFree(tAmount === 0);
        setProductCurrency(treatmentProgressDet.currency_name);
      }
      if (treatmentProgressDet?.treatment_price == "") {
        setNetPrice(0);
        setDiscount(0);
        setDiscountAmount(0);
        setTotalAmount(0);
        if (treatmentProgressDet.currency_name || productCurrency.currency_name) {
          form.setFieldValue(
            "price",
            treatmentProgressDet.currency_name
              ? `${treatmentProgressDet.currency_name} 0`
              : `${productCurrency.currency_name} 0`
          );
        }

      }

      const isRefinement = treatment?.revision_plan_version;

      //first refinement case
      if (isRefinement) {
        let isRefinementFree = true;
        // let price = 0;
        // const refinementProduct = productList.find(
        //   (el) => el.product_name.toLowerCase() === "refinement"
        // );
        // set refinement prodcut in case of refinement
        // if (refinementProduct) {
        form.setFieldValue("treatmentName", null);
        // isRefinementFree = refinementProduct.price === 0;
        // price = refinementProduct.price;
        // } else {
        setNetPrice(0);
        setTotalAmount(0);
        setDiscount(0);
        setDiscountAmount(0);
        // }
        if (treatmentProgressDet.currency_name || productCurrency.currency_name) {
          form.setFieldValue(
            "price",
            `${treatmentProgressDet.currency_name
              ? treatmentProgressDet.currency_name
              : productCurrency.currency_name
            } ${0} Free`
          );
        }

        // form.setFieldValue("free", isRefinementFree);

        setClinicProductFree(isRefinementFree);
      }
    }
  }, [productList, treatmentProgressDet]);

  // const calculateTotalPrice = (price, discount) => {
  //   var numVal1 = Number(price);
  //   var numVal2 = Number(discount);

  //   const totalValue = numVal1 * ((100 - numVal2) / 100);
  //   return Math.ceil(totalValue);
  // };

  const getDiscountPercentage = (netPrice, value) => {
    const total = Number(netPrice) - Number(value);
    const diffPercentage = (total / netPrice) * 100;
    const actualPercentage = Number(100 - isNaN(diffPercentage) ? 0 : diffPercentage).toFixed(1);
    return actualPercentage;
  };

  // const onTreatmentNameChange = async (value) => {
  //   let currency = [];
  //   const product = productList.find((el) => el.product_id === value);
  //   if (product) {
  //     // const { discount, price, product_currency_id } = product;
  //     console.log("productproduct", product)
  //     form.setFieldsValue({ 
  //       price: product.price,
  //       pricing: product.price, 
  //       // discountAmount: product.price,
  //       discountPrice: product.discount,
  //       discountPercentage:""

  //      });

  //     // if (product_currency_id) {
  //     //   const currencyOptions = [...productOrServiceCurrencyOptions];
  //     //   currency = currencyOptions.filter((obj) => obj.currency_id === product_currency_id);
  //     //   if (currency) {
  //     //     setProductCurrency(currency[0].currency_name);
  //     //   }
  //     // }

  //     // let discountPercentage = 0;
  //     // let dAmount = 0;
  //     // let tAmount = 0;

  //     // if (discount) {
  //     //   dAmount = discount;
  //     //   discountPercentage = getDiscountPercentage(price, discount);
  //     //   tAmount = price - discount;
  //     // } else {
  //     //   discountPercentage = 0;
  //     //   dAmount = 0;
  //     //   tAmount = price;
  //     // }
  //     // setNetPrice(price);
  //     // setDiscount(discountPercentage);
  //     // setDiscountAmount(dAmount);
  //     // setTotalAmount(tAmount);

  //     // form.setFieldValue(
  //     //   "price",
  //     //   currency.length ? currency[0].currency_name + " " + tAmount : tAmount
  //     // );
  //     // form.setFieldValue("free", product.free);
  //     // setClinicProductFree(product.free);
  //     // handleFreeProduct(product.free);
  //     // setDisableTPInfo(product.hidePatientPrice ? true : false);
  //     // form.setFieldValue("hideTreatmentInfo", product.hidePatientPrice ? true : false);
  //     // if (!treatmentProgressDet?.treatment_name) {
  //     //   const formData = await form.validateFields();
  //     //   await savePatientTreatmentData(formData.treatmentName);
  //     // }

  //   }
  // };
  const onTreatmentNameChange = async (value) => {
    const product = productList.find((el) => el.product_id === value);
    
    if (product) {
      const { price, discount } = product;
  
      // Calculate discount amount and discounted price
      // const discountAmount = price * (discount / 100);
      const discountedPrice = price - discount;
      const discountPercentage = discount
      ? ((discount / price) * 100).toFixed(2)
      : 0;
  
      // console.log("Selected Product:", product);
      // // console.log("Discount Amount:", discountAmount);
      // console.log("Discounted Price:", discountedPrice);
  
      // Update the form fields with calculated values
      form.setFieldsValue({
        netPrice: price - discount,
        newPrice: price, // Original price
        discountPercentage: discountPercentage, 
        discountAmount: discount, 
        discountPrice: discountedPrice.toFixed(2), 
        currency: product.currency_name,
        productFree: product.free
      });
      setProductCurrency(product.currency_name)
      setClinicProductFree(product.free)
    } else {
      console.warn("Product not found");
    }
  };
  const handleFreeProduct = (checked) => {
    const selectedTreatmentName = form.getFieldValue("treatmentName");
    if (selectedTreatmentName) {
      let productCurrency = "";
      const product = productList.find((el) => el.product_id === selectedTreatmentName);
      if (product) {
        productCurrency = productOrServiceCurrencyOptions.find(
          (el) => el.currency_id === product.product_currency_id
        )?.currency_name;
        if (!checked) {
          const totalPriceForShow = product.discount
            ? product.price - product.discount
            : product.price;
          setTotalAmount(totalPriceForShow);
          form.setFieldValue("price", `${productCurrency} ${totalPriceForShow}`);

          setDiscountAmount(product.discount);
          setNetPrice(product.price);
          const discountPercentage = getDiscountPercentage(product.price, product.discount);
          setDiscount(discountPercentage);
        } else {
          form.setFieldValue("price", `${productCurrency} ${0}`);
          setTotalAmount(0);
          setDiscountAmount(product.price);
          setNetPrice(0);
          setDiscount(100);
        }
      }
    }
  };

  // const onAmountChange = async (value, key) => {
  //   let discountPercentage = 0;
  //   let dAmount = 0;
  //   let tAmount = 0;
  //   if (key === "discount") {
  //     tAmount = calculateTotalPrice(netPrice, value);
  //     discountPercentage = value;
  //     dAmount = netPrice - tAmount;
  //   }
  //   if (key === "discountAmount") {
  //     if (value > netPrice) {
  //       return message.error(`Discount amount cannot be more than ${productCurrency} ${netPrice}`);
  //     }
  //     tAmount = netPrice - value;
  //     discountPercentage = getDiscountPercentage(netPrice, value);
  //     dAmount = value;
  //   }

  //   setDiscount(discountPercentage);
  //   setDiscountAmount(dAmount);
  //   setTotalAmount(tAmount);

  //   form.setFieldValue("price", productCurrency + " " + tAmount);
  // };

  const onFinish = (values) => {
    approveSendPlanOk();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const savePatientTreatmentData = async (treatmentName, formData) => {
    if (treatmentProgressDet?.prescriptionId) {
      const product = productList.find((el) => el.product_id === treatmentName);
      let arches;
      if (treatment?.num_of_upper_aligners > 0 && treatment?.num_of_lower_aligners > 0) {
        arches = PATIENTCONSTANTS.UPPER_AND_LOWER;
      }
      else if (treatment?.num_of_upper_aligners > 0 && treatment?.num_of_lower_aligners <= 0) {
        arches = PATIENTCONSTANTS.UPPER;
      }
      else if (treatment?.num_of_upper_aligners <= 0 && treatment?.num_of_lower_aligners > 0) {
        arches = PATIENTCONSTANTS.LOWER;
      }
      else {
        arches = treatmentProgressDet?.arches_treated;
      }
      // console.log("clinicProductFreeclinicProductFree", clinicProductFree)
      if (product) {
        const inputData = {
          clinicId: clinic.clinicId,
          patientId: patientId,
          netPrice: clinicProductFree ? 0 : formData.newPrice || formData.netPrice,
          discount: formData.discountAmount || 0,
          archesTreated: arches,
          treatmentName: product.product_name,
          labTreatmentId: treatment?.lab_treatment_id,
          clinicProductFree,
          fromApproveTab: true,
        };
        // console.log("inputrDtatatat", inputData)
        await updatePatientTreatmentData(treatmentProgressDet.prescriptionId, inputData);
      }

    }
  };

  const approveSendPlanOk = async () => {
    try {
      const formData = await form.validateFields();
      //  console.log("formDataformData", formData)
      setApproveSendPlanPopLoading(true);
      if (clinicConfig.isProductAvailable) {
        if (!formData.hideTreatmentInfo) {
          if (treatmentProgressDet?.prescriptionId) {
            await savePatientTreatmentData(formData.treatmentName, formData);
          } else {
            message.error("Prescription is not Added yet.");
            return;
          }
        }
      } 
      const isRefinement = treatment?.revision_plan_version ? true : false;

      let afterApproval = false;
      let sendToPatient = false;

      if (fromShareWithPatient) {
        afterApproval = true;
        sendToPatient = false;
      }
      else {
        afterApproval = false;
        sendToPatient = true;
      }


      if (treatment.sendToPatient != null) {
        afterApproval = true;
        sendToPatient = false;
      }

      

      await confirmTreatmentStatus(
        treatment?.lab_treatment_id,
        "approved",
        "",
        inputText,
        isRefinement,
        clinicConfig.isProductAvailable ? clinicProductFree : true,
        sendToPatient,
        clinicConfig.isProductAvailable ? formData.hideTreatmentInfo : true,
        afterApproval,
        false
      );

      setSendOrderWithoutPatientApproval(false);

      await updateClinicIntercomStatus(clinic.clinicId, {
        sendToPatient: 1,
      });
      dispatch(showOrderTourAfterSendToPatient(true))
  
    

    } catch (err) {
      console.log("err", err);
      message.error("Error occured in saving Treatment Data");
    } finally {
      setApproveSendPlanPopLoading(false);
   
      setFromShareWithPatient(false);

      if (
        clinicDoctorAppInfoSelector &&
        !clinicDoctorAppInfoSelector.appInstalled && // Modal opens only if app is NOT installed
        !clinicDoctorAppInfoSelector.skipApp // Modal opens only if skipApp is NOT true
      ) {
        setShowScanModel(true);
      }
      else{
        setIsModalApproveSendPlanVisible(false);
      }

    }
  };

  const onFreeChange = (e) => {
    const { checked } = e.target;
    setClinicProductFree(checked);
    // form.setFieldValue(
    //   "price",
    //   checked ? `${productCurrency} 0 Free` : `${productCurrency} ${totalAmount}`
    // );

    handleFreeProduct(checked);
  };

  // const onPriceChange = (value) => {
  //   setNetPrice(!value ? 0 : value);

  //   let tAmount = 0;
  //   tAmount = calculateTotalPrice(value, discount);

  //   setTotalAmount(tAmount);

  //   form.setFieldValue("price", productCurrency + " " + tAmount);
  // };

  const setHeading = () => {
    const isRefinement = treatment?.revision_plan_version;

    if (isRefinement) {
      return `Refinement Plan Version ${treatment.revision_plan_version}.0`;
    }

    return `Treatment Plan Version ${treatment.treatment_plan_version}.0`;
  };

  const handlePatientApprovePlanCancel = () => {
    setIsModalPatientApprovePlanVisible(false);
  };

  const [disableTPInfo, setDisableTPInfo] = useState(false);
  const onTPhideChange = async (e) => {
    try {
      const formData = await form.validateFields();
      setDisableTPInfo(e.target.checked);

    } catch (error) {
      console.log("error", error);
      form.resetFields(['hideTreatmentInfo']);
    }


  };

  const goToSettings = () => {
    history.push("/clinic/settings/services");
  };

  const handleScanOk= () =>{
    setShowScanModel(false)
    setIsModalApproveSendPlanVisible(false);
   }
   const onScanCancel= () =>{
     setShowScanModel(false)
     setIsModalApproveSendPlanVisible(false);
   }
  


   const setDiscountFields = (discountAmount, discountPrice, discountPercentage) => {
    // console.log(discountAmount, discountPrice, discountPercentage)
    form.setFields([
      {
        name: "discountAmount",
        value: discountAmount,
      },
      {
        name: "discountPrice",
        value: discountPrice < 0 ? 0 : discountPrice,
      },
      {
        name: "discountPercentage",
        value: discountPercentage,
      },
    ]);
  };
  
  const calculateDiscountPrice = (discountAmount, productPrice) => {
    if (!discountAmount) {
      return "0.00";
    }
    return productPrice ? (productPrice - discountAmount).toFixed(2) : "0.00";
  };
  
  const handlePriceChange = (value) => {
    // console.log("valueee", value)
    if (!value) {
    return  setDiscountFields(0, 0, 0);
    }
    const discountAmount = form.getFieldValue("discountAmount") || 0;
    const discountPercentage = ((discountAmount / value) * 100).toFixed(2);
    // const discountPrice = calculateDiscountPrice(discountAmount, value);
    // console.log("discountPricediscountPrice", discountPrice)
  
    setDiscountFields(discountAmount, discountAmount, discountPercentage);
    form.setFieldsValue({ price: value });
  };
  
  const handleDiscountPercentageChange = (percentage) => {
    // console.log("percentage", percentage);
    const productPrice = form.getFieldValue("newPrice") || 0;
    // Validate the percentage input
    if (percentage < 0 || percentage > 100) {
      return message.error("Percentage must be between 0 and 100.");
    }
  
    let discountAmount = 0;
    let discountPrice = productPrice;
  
   
      discountAmount = ((percentage / 100) * productPrice).toFixed(2);
      // console.log("discountAmountdiscountAmount", discountAmount)
      // discountPrice =  percentage > 0 ? productPrice - discountAmount : 0;
      // console.log("discountPricediscountPrice", discountPrice)
    
  
    setDiscountFields(discountAmount, discountAmount, percentage || 0);
  };
  
  const handleDiscountAmountChange = (value) => {
    if (value < 0) {
      setDiscountFields(0, 0, 0);
      return message.error("Discount cannot be a negative number.");
    }
  
    const productPrice = form.getFieldValue("newPrice") || 0;
  
    if (!productPrice || value >= productPrice) {
      setDiscountFields(value, null, null);
      return message.error("Discount amount cannot exceed or equal the product price.");
    }
  
    // const discountPrice = calculateDiscountPrice(value, productPrice);
    const discountPercentage = ((value / productPrice) * 100).toFixed(2);
  
    setDiscountFields(value, value, discountPercentage);
  };
  
  const onSaveDiscount = () => {
    const productPrice = form.getFieldValue("newPrice");
    const discountPrice = form.getFieldValue("discountPrice");
    if (discountPrice !== undefined && discountPrice !== null) {
      form.setFieldsValue({
        netPrice: (productPrice - discountPrice).toFixed(2),
      });
      // console.log("Discount saved! Pricing updated to:", discountPrice);
    
    } 
    setOpenDropDown(false)
  };
  

  

  return (
    <>
      {isModalApproveSendPlanVisible && (
        <Modal
          title="Send to patient for approval222"
          confirmLoading={approveSendPlanPopLoading}
          okText="Send"
          cancelText="Cancel"
          visible={isModalApproveSendPlanVisible}
          onOk={() =>
            clinicConfig.isProductAvailable
              ? form.submit()
              : approveSendPlanOk()
          }
          onCancel={handleApproveSendPlanCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
          width={950}
        >
          <div className="treatment-container">
            <Row>
              <Col span={8}>
                <div style={{ marginBottom: "8px" }}>
                  {/* <label>{"Treatment Plan Version"}</label>
                  <Input size={"large"} value={setHeading()}></Input> */}
                  <Alert
                    showIcon={false}
                    style={{
                      backgroundColor: "#fafafa",
                      borderColor: "#fafafa",
                      fontWeight: 600,
                      width: "215px",
                    }}
                    type="info"
                    message={setHeading()}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={18} style={{ marginBottom: "20px" }}>
                <AppAlert
                  message={
                    <span>
                      Treatment plan will be sent to{" "}
                      <strong>patient app </strong> for approval
                      <strong> but you can still proceed to order. </strong>
                    </span>
                  }
                />
                {/* <Alert
                  className="new-alert-type1"
                  message={
                    <span>
                      Treatment plan will be sent to <strong>patient app </strong> for approval
                    </span>
                  }
                  type="info"
                  showIcon
                  icon={<BulbIcon />}
                /> */}
              </Col>
            </Row>
            <Divider />

            <>
              <Row justify="space-between">
                <Col lg={12}>
                  {" "}
                  <p className="treatment-header">Lab Order Details</p>
                </Col>
                <Col lg={12}>
                  {" "}
                  <div className="treatment-colTwo">
                    <SupportToolTip title={detailInfoMessage} label="" />{" "}
                    <p
                      className="treatment-header"
                      style={{ paddingLeft: "5px" }}
                    >
                      Visible to you only
                    </p>
                  </div>
                </Col>
              </Row>

              <div className="treatment-section">
                <Row gutter={[16, 16]}>
                  <Col lg={8}>
                    <p className="treatment-label">No, of Upper Aligners</p>
                    <p className="treatment-info">
                      {treatment?.num_of_upper_aligners}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p className="treatment-label">No, of Lower Aligners</p>
                    <p className="treatment-info">
                      {treatment?.num_of_lower_aligners}
                    </p>
                  </Col>
                  {/* <Col lg={8}>
                    <p className="treatment-label">Attachments</p>
                    <p className="treatment-info">
                      {Boolean(treatment?.attachments) === true ? "Yes" : "No"}
                    </p>
                  </Col> */}
                </Row>

                <TreatmentProductsTable treatment={treatment} />
              </div>
              {clinicConfig.isProductAvailable ? (
                <>
                  <Divider />
                  <Row justify="space-between">
                    <Col lg={12}>
                      {" "}
                      <p className="treatment-header">Clinic Section</p>
                    </Col>
                    <Col lg={12}>
                      {" "}
                      <div className="treatment-colTwo">
                        <p className="treatment-header">
                          Visible on Patient App
                        </p>{" "}
                        <SupportToolTip title={approvalInfoMessage} label="" />
                      </div>
                    </Col>
                  </Row>
                  <Form
                    layout="vertical"
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    className="treatment-form"
                  >
                    <Row>
                      <Col md={24}>
                        <Form.Item
                          valuePropName="checked"
                          name="hideTreatmentInfo"
                        >
                          <Checkbox onChange={onTPhideChange}>
                            Hide Treatment Name & Price from Patient
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    {!disableTPInfo ? (
                      <>
                        <Row gutter={[16, 16]}>
                          <Col md={12}>
                            <Form.Item
                              label="Treatment Name"
                              name="treatmentName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a treatment",
                                },
                              ]}
                            >
                              <Select
                                disabled={disableTPInfo}
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="Select Treatment"
                                onChange={onTreatmentNameChange}
                              >
                                {productList?.map(item => {
                                  return (
                                    <Option
                                      value={item.product_id}
                                      key={item.product_id}
                                    >
                                      {item.product_name} - {item.product_type}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={12} style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "-0.4rem",
                                right: "0",
                              }}
                            >
                              <Form.Item
                                name="productFree"
                                label={""}
                                style={{
                                  textAlign: "left",
                                }}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  disabled={disableTPInfo}
                                  onChange={onFreeChange}
                                >
                                  Free
                                </Checkbox>
                              </Form.Item>
                            </div>
                            <Form.Item
                              label="Price"
                              name="netPrice"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your price!",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                placeholder="Enter price"
                                disabled
                                step={0.01}
                                precision={2}
                                addonAfter={
                                  !clinicProductFree && (
                                    <Dropdown
                                      className="cost-dropdown"
                                      overlay={
                                        <Menu className="conflict-menu">
                                          <Menu.Item>
                                            <Row
                                              align="middle"
                                              gutter={[16, 16]}
                                              style={{
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <Col lg={9}>
                                                <span>Price per Unit</span>
                                              </Col>
                                              <Col lg={15}>
                                                <Form.Item
                                                  label=""
                                                  name="newPrice"
                                                  style={{
                                                    margin: "0px",
                                                  }}
                                                >
                                                  <InputNumber
                                                    disabled={clinicProductFree}
                                                    className="product-price"
                                                    placeholder="Price"
                                                    min={0}
                                      
                                                    step={0.1}
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    onChange={handlePriceChange}
                                                    suffix={
                                                      <span
                                                        style={{
                                                          color: primaryColor,
                                                        }}
                                                      >
                                                         {productCurrency}
                                                      </span>
                                                    }
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                            <Row gutter={[8, 8]} align="middle">
                                              <Col lg={9}>
                                                <span>Discount</span>
                                              </Col>

                                              <Col lg={5}>
                                                <Form.Item
                                                  label=""
                                                  name="discountPercentage"
                                                  placeholder="Discount Percentage"
                                                  style={{ margin: "0px" }}
                                                >
                                                  <InputNumber
                                                    placeholder="%"
                                                    min={0}
                                                    max={100}
                                                    step={0.1}
                                                    onChange={handleDiscountPercentageChange}
                                                    suffix={
                                                      <b
                                                        style={{
                                                          color: primaryColor,
                                                        }}
                                                      >
                                                        %
                                                      </b>
                                                    }
                                                    style={{ width: "100%" }}
                                                    // onChange={onDiscountPercentageChange}
                                                  />
                                                </Form.Item>
                                              </Col>

                                              <Col lg={10}>
                                                <Form.Item
                                                  label=""
                                                  name="discountAmount"
                                                  placeholder="Discount Amount"
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                >
                                                  <InputNumber
                                                    placeholder="Amount"
                                                    step={0.01}
                                                    precision={2}
                                                    style={{ width: "100%" }}
                                                    suffix={
                                                      <span
                                                        style={{
                                                          color: primaryColor,
                                                        }}
                                                      >
                                                        {productCurrency}
                                                      </span>
                                                    }
                                                    onChange={handleDiscountAmountChange}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                            <Row
                                              align="middle"
                                              gutter={[16, 16]}
                                              style={{
                                                marginTop: "10px",
                                              }}
                                            >
                                              <Col lg={9}>
                                                <b>Discount Price</b>
                                              </Col>
                                              <Col lg={15}>
                                                <Form.Item
                                                  label=""
                                                  name="discountPrice"
                                                  style={{
                                                    margin: "0px",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <InputNumber
                                                    min={0}
                                                    step={0.1}
                                                    style={{
                                                      width: "100%",
                                                      color: "#000",
                                                    }}
                                                    className="DiscountPrice product-price"
                                                    disabled
                                                    suffix={
                                                      <b
                                                        style={{
                                                          color: "#000",
                                                        }}
                                                      >
                                                        {productCurrency}
                                                      </b>
                                                    }
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                            <Button
                                              style={{
                                                marginTop: "10px",
                                              }}
                                              type="primary"
                                              block
                                             onClick={() => onSaveDiscount()}
                                            >
                                              Save
                                            </Button>
                                          </Menu.Item>
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                     visible={openDropdown}
                                      onVisibleChange={visible =>
                                         visible
                                           ? setOpenDropDown(true)
                                           : setOpenDropDown(false)
                                      }
                                    >
                                     <div onClick={() =>setOpenDropDown(true)}>
                                     <CustomEditIcon />
                                      </div> 
                                    </Dropdown>
                                  )
                                }
                                style={{ width: "100%" }}
                       
                              />
                              {/* <Select
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="Price"
                                disabled={clinicProductFree || disableTPInfo}
                        
                                popupClassName="netPrice-dropdown"
                                dropdownRender={(menu) => (
                                  <div className="price-dropDown">
                                    <div className="row-one">
                                      <p>Price</p>
                                      <InputNumber
                                        size="small"
                                        type="number"
                                        style={{ width: "100%" }}
                                        min={0}
                                        placeholder="Price"
                                        value={netPrice}
                                        controls={false}
                                        disabled={clinicProductFree}
                                
                                        onChange={onPriceChange}
                                        onKeyDown={(e) => e.stopPropagation()}
                                        suffix={
                                          <b style={{ color: primaryColor }}>
                                            {productCurrency}
                                          </b>
                                        }
                                      />
                                    </div>

                                    <div className="row-one">
                                      <p>Discount</p>
                                      <div className="row-two">
                                        <InputNumber
                                          size="small"
                                          type="number"
                                          //  max={100}
                                          min={0}
                                          placeholder="Discount"
                                          className="discount-amount-input"
                                          suffix="%"
                                          disabled={clinicProductFree}
                                          controls={false}
                                          value={discount}
                                          onChange={(value) => {
                                            if (value > 100) {
                                              message.error(
                                                `Discount cannot be more than 100%`
                                              );
                                            } else {
                                              onAmountChange(value, "discount");
                                            }
                                          }}
                                          onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <InputNumber
                                          size="small"
                                          type="number"
                                          className="discount-amount-input"
                                          controls={false}
                                  
                                          disabled={clinicProductFree}
                                          min={0}
                                          placeholder="Amount"
                                          suffix={
                                            <b style={{ color: primaryColor }}>
                                          
                                            </b>
                                          }
                                          value={discountAmount}
                                          onChange={(value) => {
                                            onAmountChange(value, "discountAmount");
                                          }}
                                          onKeyDown={(e) => e.stopPropagation()}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="price-footer"
                                      style={{ background: primaryColor }}
                                    >
                                      <p>Total</p>
                                      <b>{`${productCurrency} ${totalAmount !== 0 ? totalAmount : "0 Free"
                                        }`}</b>
                                    </div>
                                  </div>
                                )}
                              /> */}
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Form>
                  {!disableTPInfo ? (
                    <Row>
                      <Col md={24}>
                        <AppAlert
                          message={
                            <span>
                              Name and price will be shared with the patient.
                              They'll see duration, arches, and notes. Name
                              incorrect? Update in{" "}
                              <span
                                onClick={goToSettings}
                                style={{ color: primaryColor }}
                                className={styles["hightlight"]}
                              >
                                settings
                              </span>{" "}
                              .
                            </span>
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md={24}>
                        <AppAlert
                          message={
                            <span>
                              When marked as 'Hide', the Treatment name and
                              price will not be shared with the patient on the
                              patient app. Instead the patient will only see the
                              3D treatment plan, arches treated and the duration
                              of the treatment as information only.
                            </span>
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <div style={{ width: "100%" }}>
                      <label>Treatment Details</label>
                      <div className="treatment-details-notes">
                        <TextEditor
                          // disabled={disableTPInfo}
                          editoInput={inputText}
                          setEditorInput={setInputText}
                          hideToolbar={true}
                          placeholder="Write here"
                          image={false}
                        />
                      </div>
                    </div>
                  </Row>
                </>
              ) : null}
            </>
          </div>
        </Modal>
      )}

      <Modal
        title="Are you sure?"
        confirmLoading={approveSendPlanPopLoading}
        okText="Confirm"
        cancelText="Cancel"
        visible={isModalPatientApprovePlanVisible}
        onOk={approveSendPlanOk}
        onCancel={handlePatientApprovePlanCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <p style={{ fontWeight: "600", marginBottom: "0" }}>
          Approve Treatment Plan without sending to patient.
        </p>
        <p style={{ marginTop: "0px" }}>
          Make sure you have consent offline with patient.
        </p>
      </Modal>

      {showScanModel && (
        <DoctorAppModel
          isModalVisible={showScanModel}
          handleOk={handleScanOk}
          handleCancel={onScanCancel}
          doNotShow
        />
      )}
    </>
  );
};

export default ApproveSentToPatient;
