import { useState, useEffect } from "react";
import { Form, Select, Row, Radio, Col, Input } from "antd";
import { memo } from "react";

import { FORM_CONSTANTS } from "../constants";

import { getTreatmentTypes } from "api/clinic";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import { getPhasingInfo } from "messages/messageController";
import { companyType } from "colors-config";
const { TextArea } = Input;
const { Option } = Select;

const SmileAlignTreatmentType = ({
  onFormChange,
  disabled,
  showPrescriptionPhasing,
  treatmentOptions
}) => {
  // const [showCommentArray, setShowCommentArray] = useState([true]);
  // const [treatmentOptions, setTreatmentOptions] = useState([]);

  // const getPrescriptionTreatmentTypes = async () => {
  //   try {
  //     const treatmentRes = await getTreatmentTypes();
  //     if (treatmentRes?.data?.data) {
  //       setTreatmentOptions(treatmentRes?.data?.data);
  //     }
  //   } catch (error) {
  //     //   message.error("Some Error occured!");
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   getPrescriptionTreatmentTypes();
  // }, []);

  return (
    <>
      <h1 className="form-heading" style={{ margin: "0px 0px 10px 0px" }}>
      1. Service Type
      </h1>
      <div className="treatment-row aligner-row" style={{ width: "70%" }}>
        <Form.Item
          name="smilelignTreatmentTypeId"
          label={companyType ===  7 ? "Service Type" : "Treatment Type"}
          rules={[
            {
              required: true,
              message: "Please select an Option",
            },
          ]}
        >
          <Select
            disabled={disabled}
            size="large"
            className="form-select"
            placeholder="Select Treatment Type"
            notFoundContent={<p>No treatment type added in the clinic</p>}
            onChange={(value) =>
              onFormChange("smilelignTreatmentTypeId", value)
            }
          >
            {treatmentOptions?.map((item, i) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.treatmentName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="smilelignArches"
          label={companyType ===  7 ? "Treatment Type" : "Arches Treated"}
          rules={[
            {
              required: true,
              message: "Please select an Option",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => onFormChange("smilelignArches", e.target.value)}
            disabled={disabled}
          >
            <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
              Upper and Lower
            </Radio>
            <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
            <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      {/* {companyType === 5 ? */}
        <div className="phasing-row">
          <Row gutter={[8, 8]}>
            <Col lg={5} style={{ paddingTop: '35px'}}>
              <div className="label">
                <label>
                  <span style={{ color: "#ff4d4f" }}>*</span>Phasing
                  <AppToolTip title={getPhasingInfo()}>
                    {/* <AppInfoIcon iconStyles={{ marginLeft: "10px" }}/> */}
                  </AppToolTip>
                </label>
              </div>
            </Col>
            <Col lg={6} style={{ paddingTop: '30px'}}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select an Option",
                  },
                ]}
                name="phasing"
                label=""
              >
                <Radio.Group
                  onChange={(e) => onFormChange("phasing", e.target.value)}
                  disabled={disabled}
                >
                  <Radio value={FORM_CONSTANTS.NO}>No</Radio>
                  <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          {showPrescriptionPhasing && (
              <Col lg={12}>
                <Form.Item 
                  name="prescriptionPhasing" 
                  label="Required Phases"
                  rules={[
                    {
                      required: true,
                      message: "Please add phasing details",
                    },
                  ]}>
                  <Input
                    placeholder="Enter details"
                    onChange={(e) =>
                      onFormChange("prescriptionPhasing", e.target.value)
                    }
                    disabled={disabled}
                      size="large"
                  />
                </Form.Item>
              </Col>
          )}
          </Row>
        </div> 
        {/* : null */}
      {/* } */}
    </>
  );
};

export default memo(SmileAlignTreatmentType);
