import { memo } from "react";
import { Form, Row, Radio, Col } from "antd";
import { FORM_CONSTANTS } from "../constants";
import { companyType } from "colors-config";
import { spacingInfo } from "messages/messages";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";



const IPRAlignment = ({ onFormChange, disabled, title, titleTwo }) => {



  return (
    <div>
   <h1 className="form-heading">{titleTwo}</h1>

 
      <Row className="incisal-row">
        <Col lg={8}>
          <label>Incisal edge alignment</label>
        </Col>
        <Col lg={16}>
          <Form.Item name="alignment" label="">
            <Radio.Group
              onChange={(e) => onFormChange("alignment", e.target.value)}
              disabled={disabled}
            >
              <Radio value={FORM_CONSTANTS.GINGIVAL}>Gingival</Radio>
              <Radio value={FORM_CONSTANTS.INCISAL}>Incisal</Radio>
              <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
              <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <div className="ipr-row">
        <Row>
          <Col lg={8}>
            <label>Crowding</label>
          </Col>
          <Col lg={16}>
            <Form.Item name="crowding" label="">
              <Radio.Group
                onChange={(e) => onFormChange("crowding", e.target.value)}
                disabled={disabled}
              >
                <Radio value={FORM_CONSTANTS.EXPANDING}>Expand</Radio>
                <Radio value={FORM_CONSTANTS.PROCLINING}>Procline</Radio>
                <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row className="spacing-row">
        <Col lg={8}>
          <label>
            <span style={{ marginRight: "10px" }}>Spacing</span>
            {companyType === 5 && (
              <AppToolTip
                title={spacingInfo}
              >
              {/* <AppInfoIcon iconStyles={{ marginLeft: "0px" }}/> */}
              </AppToolTip>
            )}
          </label>
        </Col>
        <Col lg={16}>
          <Form.Item name="spacing" label="">
            <Radio.Group
              onChange={(e) => onFormChange("spacing", e.target.value)}
              disabled={disabled}
            >
              <div>
                <Radio value={FORM_CONSTANTS.BETWEEN_CENTRALS}>
                  Between Centrals
                </Radio>
                <Radio value={FORM_CONSTANTS.DISTAL_TO_LATERALS}>
                  Distal to laterals
                </Radio>
                <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Radio value={FORM_CONSTANTS.DISTAL_TO_CANINES}>
                  Distal to Canines&nbsp;
                </Radio>
                <Radio value={FORM_CONSTANTS.EQUALLY_AROUND_lATERALS}>
                  Equally around laterals
                </Radio>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </div>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <h1 className="form-heading" style={{ margin: "10px 0px 25px 0px" }}>
        {title}
      </h1>
      <div className="ipr-row">
        <Row>
          <Col lg={8}>
            <label>IPR</label>
          </Col>
          <Col lg={16}>
            <Form.Item name="ipr" label="">
              <Radio.Group
                onChange={(e) => onFormChange("ipr", e.target.value)}
                disabled={disabled}
              >
                <Radio value={FORM_CONSTANTS.NO}>No</Radio>
                <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                {companyType !== 5 ? (
                  <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
                ) : (
                  ""
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="ipr-row">
        <Row>
          <Col lg={8}>
            <label>Attachments</label>
          </Col>
          <Col lg={16}>
            <Form.Item name="attachments" label="">
              <Radio.Group
                onChange={(e) => onFormChange("attachments", e.target.value)}
                disabled={disabled}
              >
                <Radio value={FORM_CONSTANTS.NO_ATTACHMENTS}>No</Radio>
                <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                {companyType !== 5 ? (
                  <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
                ) : (
                  ""
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>

     
    
    </div>
  );
};

export default memo(IPRAlignment);