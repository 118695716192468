import { useState, useEffect } from "react";
import { Badge } from "antd";
import AppButton from "components/shared/AppButton/AppButton";
import ChatIcon from "components/shared/Icon/ChatIcon";
import ChatDot from "components/shared/Icon/ChatDot";
import { connectSocket, socket } from "api/chat";

import "./chat.scss";
import ChatDrawer from "./components/ChatDrawer";
import { primaryColor } from "colors-config";

const ChatWindow = ({
  clinicId,
  patientId,
  partneredLabs,
  patientName,
  showCommentBox,
  openPatientChatTab,
  disableCommentBox,
  senderId,
  clinicInfo,
}) => {
  const chatInfo = {
    clinic_id: clinicId,
    lab_id: partneredLabs[0]?.lab_id,
    patient_id: patientId,
  };

  const [open, setOpen] = useState(showCommentBox ?? false);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (socket) {
      socket.emit("joinRoom", chatInfo);
      socket.emit("joinRoom", {
        clinic_id: chatInfo.clinic_id,
        patient_id: patientId,
      });
      socket.on("message", (comment) => {
        if (comment.module !== "clinic") {
          setUnreadCount((prevCount) => {
            return prevCount + 1;
          });
        }
      });
    }
    else{
      connectSocket();
    }
    return () => {
      socket.off("message", () => {
        console.log("off socket");
      });
    };
  }, [socket]);

  const showDrawer = () => {
    setOpen(!open)
    setUnreadCount(0);
  };

  return (
    <div  className="chat-window-container">
      <AppButton
        className="chat-window-btn"
        type="primary"
        icon={
          unreadCount ? (
            <Badge count={unreadCount}>
              <ChatDot className="chat-icon"/>
            </Badge>
          ) : (
            <ChatIcon className="chat-icon" style={{color: primaryColor}}/>
          )
        }
        onClick={showDrawer}
      />
      {open && (
        <ChatDrawer
          open={open}
          setOpen={setOpen}
          clinicId={clinicId}
          patientId={patientId}
          partneredLabs={partneredLabs}
          patientName={patientName}
          openPatientChatTab={openPatientChatTab}
          disableCommentBox={disableCommentBox}
          clinicInfo={clinicInfo}
        />
      )}
    </div>
  );
};

export default ChatWindow;
