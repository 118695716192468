
import dayjs from "dayjs";
import { Row, Col, Divider } from "antd";
import {ReactComponent as UserPdf} from "assets/images/UserPdf.svg";
import { primaryColor } from "colors-config";
import { getDisplayGender } from "utilities/Patient";
const PatientDetails = ({ patientInfo, clinic }) => {
    const clinicDetails=clinic?.multipleAddresses?.length && clinic?.multipleAddresses.find(obj=>obj.default);

  return (
    <div className="treatmentPDF-patientContainer">
        <Divider/>
        <Row align="top" justify="space-between" gutter={[48,48]}>
        <Col lg={2} span={2}>
         <UserPdf/>
        </Col>
        <Col lg={11} span={11}>
        <h3 style={{margin:"0px"}}>Patient Details</h3>
          <div className="treatmentPDF-patientRow">
            <label>Patient Name</label>
            <b>
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_first_name
                : ""}{" "}
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_last_name
                : "--"}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Gender</label>
            <b>
              {getDisplayGender(patientInfo)}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Date of Birth</label>
            <b>
              {patientInfo?.patient_date_of_birth
                ? dayjs(patientInfo?.patient_date_of_birth).format("DD-MM-YYYY")
                : "--"}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Phone Number</label>
            <b>
            {patientInfo?.patient_country_code_name
                ? `${patientInfo?.patient_country_code_name}-`
                : "--"}
              {patientInfo?.patient_phone_number
                ? patientInfo?.patient_phone_number
                : "--"}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Address</label>
            <b>
              {patientInfo?.patient_address
                ? `${patientInfo?.patient_address},`
                : "--"}
              {patientInfo?.patient_address &&       <br/>}
                {patientInfo?.patient_city
                ? `${patientInfo?.patient_city}`
                : ""}
                {patientInfo?.patient_county
                ? `,${patientInfo?.patient_county},`
                : ""}
                {patientInfo?.patient_county &&       <br/>}
                {patientInfo?.patient_country
                ? `${patientInfo?.patient_country}`
                : ""}
                {patientInfo?.patient_postal_code
                ? `,${patientInfo?.patient_postal_code}`
                : ""}
            </b>
          </div>
        </Col>
        <Col lg={11} span={11}>
        <h3 style={{margin:"0px"}}>Clinician Details</h3>
          <div className="treatmentPDF-patientRow">
            <label>Clinician Name</label>
            <b style={{color: primaryColor}}>
                {clinic?.clinicName
                ? `${clinic?.clinicName}`
                : "--"}
               
            </b>
          </div>
          {/* <div className="treatmentPDF-patientRow">
            <label>Doctor Name</label>
            <b>
              {patientInfo?.clinicianDetails?.clinician_name
                ? patientInfo?.clinicianDetails?.clinician_name
                : "--"}
            </b>
          </div> */}
          <div className="treatmentPDF-patientRow">
            <label>Email </label>
            <span>
              {" "}
              {clinic?.clinicEmail
                ? clinic?.clinicEmail
                : "--"}
            </span>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Phone Number</label>
            <span>
            {clinic?.clinicCountryCode
                ? clinic?.clinicCountryCode
                : ""}
              -
              {clinic?.clinicPhoneNumber
                ? clinic?.clinicPhoneNumber
                : "--"}
            </span>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Address</label>
            <span> 
              <span style={{fontWeight:"bold"}}>{clinicDetails?.label
                ? `${clinicDetails?.label} : `
                : ""}</span>
                {clinicDetails?.address
                ? `${clinicDetails?.address}, `
                : "--"}
         
                {clinicDetails?.address &&    <br/>}
                {clinicDetails?.city
                ? `${clinicDetails?.city}`
                : ""}
                {clinicDetails?.county
                ? `, ${clinicDetails?.county}, `
                : ""}
                {clinicDetails?.county &&       <br/>}
                {clinicDetails?.country
                ? `${clinicDetails?.country}`
                : ""}
                {clinicDetails?.zipCode
                ? `, ${clinicDetails?.zipCode}`
                : ""}
                
                
                </span>
          </div>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default PatientDetails;
